import { setDoctorExpert, setConnectDoctorWithExpert } from "../../../actions/v1/dftActions";
import { ApiClient, ApiMethods } from "../../core/api/ApiClient";
import ApiEndpoints from "../../core/api/ApiEndpoints";
import { DOCTOR_EXPERT_GET, DOCTOR_CONNECT_WITH_EXPERT } from "../../types";

export const dftFeature =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    next(action);
    switch (action.type) {
      case DOCTOR_EXPERT_GET:
        try {
        const res = await ApiClient({
            method: ApiMethods.POST,
            endpoint: ApiEndpoints.dftCheckExpert,
            data: action.payload,
            feature: DOCTOR_EXPERT_GET,
          });
            dispatch(setDoctorExpert(res));          
        } catch (error) {
          dispatch(setDoctorExpert([]));
        }
        break;

        case DOCTOR_CONNECT_WITH_EXPERT:
        try {
          const res = await ApiClient({
            method: ApiMethods.POST,
            endpoint: ApiEndpoints.dftConnectWithExpert,
            data: action.payload,
            feature: DOCTOR_CONNECT_WITH_EXPERT,
          });

          if (res?.responseStatus?.statusCode) {
            dispatch(setConnectDoctorWithExpert(res?.responseData));
          }
        } catch (error) {
          dispatch(setConnectDoctorWithExpert([]));
        }

        break;
      default:
        break;
    }
  };