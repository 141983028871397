import { GET_SKUS_LIST, SET_SKUS_LIST } from "../../middlewares/types";

export const getSkusList = (data) => ({
  type: GET_SKUS_LIST,
  payload: data,
});

export const setSkusList = (data) => ({
  type: SET_SKUS_LIST,
  payload: data,
});
