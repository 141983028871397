import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import CommonTableContainer from "../../common/PaginationTable";
import {
  PAGE_SIZE,
} from "../../../constant/userListingConstants";
import moment from "moment";
import Navbar from "../../navbar";
import { getContentItems } from "../../../actions/content/index";
import { contentItemsSelector } from "../../../selector/content";
import { CircularProgress } from '@mui/material';
import './contentItems.css';

const ContentItemComponent = ({
  contentItems,
  navigateToContentDetails,
  currentPage,
  onPageChange,
}) => {
  if (!contentItems || contentItems.fetching) {
    return (
      <div className="loading-container">
        <CircularProgress size={50} color="primary" /> 
        <p>Fetching data...</p>
      </div>
    );
  } else if (contentItems.error) {
    return (
      <div className="error-container">
        <p>Something went wrong. Please try again later.</p>
      </div>
    );
  } else if (contentItems.data.content.length === 0) {
    return (
      <div className="empty-container">
        <p>No content to display</p>
      </div>
    );
  }

  const columns = [
    {
      field: "action",
      headerName: "Action",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <button
          type="button"
          className="btn"
          style={{
            backgroundColor: "#373750",
            color: "white",
            padding: "10px 20px",
            fontSize: "14px",
            fontWeight: "600",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            transition: "all 0.3s ease",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }}
          onClick={() => navigateToContentDetails(params.row.id)}
          onMouseOver={(e) => {
            e.target.style.backgroundColor = "#4A4A6A"; // Darker shade on hover
          }}
          onMouseOut={(e) => {
            e.target.style.backgroundColor = "#373750"; // Revert back to original color
          }}
        >
          Details
        </button>
      ),
    },
    {
      field: "title",
      headerName: "Title",
      width: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "contentType",
      headerName: "Content Type",
      width: 180,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "updatedAt",
      headerName: "Last Updated At",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "comments",
      headerName: "Comments",
      width: 120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "likes",
      headerName: "Likes",
      width: 120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "shares",
      headerName: "Shares",
      width: 120,
      headerAlign: "center",
      align: "center",
    },
  ];

  const data = contentItems.data.content.map((element) => ({
    id: element.content_id,
    action: `<button
              type="button"
              class="btn"
              style="background-color: #373750; color: white;"
              onclick="navigateToContentDetails(${element.content_id})">
              Details
            </button>`,
    title: element.title || "NA",
    contentType:
      element.content_type[0].toUpperCase() + element.content_type.slice(1),
    updatedAt: element.updated_at
      ? moment(element.updated_at).format("MMM Do YY, h:mm a")
      : moment(element.created_at).format("MMM Do YY, h:mm a"),
    comments: contentItems.data.comments?.[element.content_id] || 0,
    likes: contentItems.data.likes?.[element.content_id] || 0,
    shares: contentItems.data.share?.[element.content_id] || 0,
  }));

  return (
    <CommonTableContainer
      rows={data}
      columns={columns}
      pageSize={PAGE_SIZE}
      currentPage={currentPage}
      totalRecords={contentItems.data.totalRecords || 0}
      onPageChange={onPageChange}
    />
  );
};

const loadingStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',  // Full viewport height
};

const errorStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  color: 'red',
};

const emptyStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
};

const ContentItem = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(0);
  const [searchTitle, setSearchTitle] = useState("");

  const contentItems = useSelector(contentItemsSelector);

  useEffect(() => {
    fetchData({ page: currentPage, pageSize: PAGE_SIZE, title: searchTitle });
  }, [dispatch, currentPage, searchTitle]);

  const fetchData = ({ page, pageSize, title }) => {
    dispatch(getContentItems({ page, pageSize, title }));
  };

  const navigateToContentDetails = (id) => {
    history.push(`/createContent/${id}`);
  };

  return (
    <Navbar pageTitle="Content">
      <div className="col-6 d-flex flex-column align-items-left mb-4">
        <label htmlFor="searchCoupon" className="col-form-label">
          Search by Title:
        </label>
        <input
          type="text"
          id="searchCoupon"
          className="form-control"
          placeholder="Search by title..."
          onChange={(e) => setSearchTitle(e.target.value)}
        />
      </div>
      <ContentItemComponent
        contentItems={contentItems}
        navigateToContentDetails={navigateToContentDetails}
        currentPage={currentPage}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </Navbar>
  );
};

export default ContentItem;
