import {
  DOCTOR_EXPERT_GET,
  DOCTOR_EXPERT_SET,
  DOCTOR_CONNECT_WITH_EXPERT,
  DOCTOR_CONNECT_WITH_EXPERT_SET,
  DOCTOR_CONNECT_WITH_EXPERT_RESET
} from "../../middlewares/types";

export const getDoctorExpert = (data) => ({
  type: DOCTOR_EXPERT_GET,
  payload: data,
});

export const setDoctorExpert = (data) => ({
  type: DOCTOR_EXPERT_SET,
  payload: data,
});

export const connectDoctorWithExpert = (data) => ({
  type: DOCTOR_CONNECT_WITH_EXPERT,
  payload: data,
});

export const setConnectDoctorWithExpert = (data) => ({
  type: DOCTOR_CONNECT_WITH_EXPERT_SET,
  payload: data,
});

export const resetConnectDoctorWithExpert = (data) => ({
  type: DOCTOR_CONNECT_WITH_EXPERT_RESET,
  payload: data,
});

