import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Alert from "sweetalert2";
import { getProvider } from "../../../actions/provider";
import { getOfferingType } from "../../../actions/offering";
import { getAllUsers } from "../../../actions/users";
import { offeringTypeSelector } from "../../../selector/offering";
import { providerSelector } from "../../../selector/provider";
import { usersSelector } from "../../../selector/users";
import "./searchStyles.css";
import { createEvent, editEvent } from "../../../actions/event";
import moment from "moment";
import UserTable from "./UserTable";
import CreatableSelect from "react-select/creatable";
import EventItemWrapper from "./EventItemWrapper";
import {
  getExpertAvailability,
  resetExpertAvailability,
} from "../../../actions/v1/eventsAction";
import { isArrayAndNotNull, isObjectAndNotNull } from "../../../utils";
import Colors from "../../../constant/Colors";

const EventForm = ({
  name,
  type,
  typeId,
  by,
  byId,
  st,
  time,
  dur,
  participants,
  eventId,
  joinUrl,
  role,
}) => {
  const [eventName, setEventName] = useState(name || "");
  const [validateEventName, setValidateEventName] = useState(true);
  const [eventType, setEventType] = useState(type || "");
  const [eventTypeId, setEventTypeId] = useState(typeId || "");
  const [validateEventType, setValidateEventType] = useState(true);
  const [ledBy, setLedBy] = useState(by || "");
  const [validateLedBy, setValidateLedBy] = useState(true);
  const [ledById, setLedById] = useState(byId || "");
  const [startDate, setStartDate] = useState(st);
  const [startTime, setStartTime] = useState(time);
  const [duration, setDuration] = useState(dur || "");
  const [eventLink, setEventLink] = useState(joinUrl || "");
  const [validateEventLink, setValidateEventLink] = useState(true);
  const [validateDuration, setValidateDuration] = useState(true);
  const [repeatMonth, setRepeatMonth] = useState(false);
  const [repeatWeek, setRepeatWeek] = useState(false);
  const [repeatDays, setRepeatDays] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(participants || []);
  const [selectedOption, setSelectedOption] = useState([]);
  const [roleId, setRoleId] = useState(role || "");

  const start = `${startDate} ${startTime}`;

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getAllUsers({}));
    dispatch(getOfferingType());
    dispatch(getProvider());

    setEventName(name || "");
    setEventType(type || "");
    setLedBy(by || "");
    setLedById(byId || "");
    // setStartDate(st || moment(new Date()).format("YYYY-MM-DD"));
    // setStartTime(time || "12:00");
    setDuration(dur || "");
    setEventLink(joinUrl || "");
    setRoleId(role || "");

    const script = document.createElement("script");
    script.src = "/assets/js/advancedForm.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [dispatch, name]);

  const offeringType = useSelector((state) => offeringTypeSelector(state));
  const provider = useSelector((state) => providerSelector(state));

  const map = new Map();
  map.set("OFRT001", "ROLE0004");
  map.set("OFRT002", "ROLE0004");
  map.set("OFRT003", "ROLE0004");
  map.set("OFRT004", "ROLE0004");
  map.set("OFRT005", "ROLE0004");
  map.set("OFRT008", "ROLE0004");
  map.set("OFRT011", "ROLE0004");
  map.set("OFRT006", "ROLE0005");
  map.set("OFRT007", "ROLE0006");
  map.set("OFRT010", "ROLE0003");

  useEffect(() => {
    if (ledById && duration && startDate && startTime) {
      dispatch(
        getExpertAvailability({
          ledById: ledById,
          duration: duration,
          start: `${startDate} ${startTime}`,
        })
      );
    }

    return () => {
      dispatch(resetExpertAvailability());
    };
  }, [dispatch, ledById, duration, startDate, startTime]);

  const { expertAvailability } = useSelector((state) => state.events);
  const { isLoading, payload } = expertAvailability;

  const { users: allUsers } = useSelector((state) => usersSelector(state));

  const options = allUsers
    ? allUsers.map((user) => ({
        label: `${user.firstName} ${user.lastName} (${user.callingNumber})`,
        value: user.userId,
      }))
    : [];

  const putDays = (day) => {
    const daySet = new Set(repeatDays);
    if (daySet.has(day)) {
      daySet.delete(day);
      setRepeatDays(Array.from(daySet));
    } else {
      daySet.add(day);
      setRepeatDays(Array.from(daySet));
    }
  };
  const clearSelections = () => {
    setRepeatWeek(false);
    setRepeatMonth(false);
    setRepeatDays([]);

    document.querySelectorAll(".day-checkbox").forEach((checkbox) => {
      checkbox.checked = false;
    });
  };

  const renderRepeat = () => {
    return (
      <div className="card">
        <h6 className="card-header">Repeat this event</h6>
        <div className="card-body">
          <div>
            {[
              "Sunday",
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
            ].map((day, index) => (
              <div className="form-check" key={index}>
                <input
                  type="checkbox"
                  className="form-check-input day-checkbox"
                  id={`customCheck${index}`}
                  checked={repeatDays.includes(index)}
                  onChange={() => putDays(index)}
                />
                <label
                  className="form-check-label"
                  htmlFor={`customCheck${index}`}
                >
                  {day}
                </label>
              </div>
            ))}
          </div>
          <hr />
          <div
            className={`form-check form-radio-success mb-2 ${
              repeatDays.length === 0 ? "text-muted" : ""
            }`}
            onClick={() => {
              if (repeatDays.length > 0) {
                setRepeatMonth(true);
                setRepeatWeek(false);
              }
            }}
          >
            <input
              type="radio"
              id="monthRadio"
              name="repeatOption"
              className="form-check-input"
              checked={repeatMonth}
              disabled={repeatDays.length === 0}
              readOnly
            />
            <label className="form-check-label" htmlFor="monthRadio">
              Repeat this for a month
            </label>
          </div>
          <div
            className={`form-check form-radio-success mb-2 ${
              repeatDays.length === 0 ? "text-muted" : ""
            }`}
            onClick={() => {
              if (repeatDays.length > 0) {
                setRepeatWeek(true);
                setRepeatMonth(false);
              }
            }}
          >
            <input
              type="radio"
              id="weekRadio"
              name="repeatOption"
              className="form-check-input"
              checked={repeatWeek}
              disabled={repeatDays.length === 0}
              readOnly
            />
            <label className="form-check-label" htmlFor="weekRadio">
              Repeat this for a week
            </label>
          </div>
        </div>
        <button className="btn btn-danger" onClick={clearSelections}>
          Clear
        </button>
      </div>
    );
  };

  const handleAddParticipant = () => {
    const user = allUsers.find(
      (element) => element.userId === selectedOption.value
    );
    setSelectedUsers([...selectedUsers, user]);
  };

  const handleChange = (e) => {
    if (e?.__isNew__) {
      const contact = e?.value;
      dispatch(getAllUsers({ contact }));
    } else {
      setSelectedOption(e);
    }
  };

  const handleDeleteParticipant = (target) => {
    setSelectedUsers(selectedUsers.filter((user) => user.userId !== target));
  };

  const handleLedBy = (event) => {
    setLedBy(event.target.value);
    setLedById(event.target.value.split(" ").slice(-1)[0]);
  };

  const handleCreateEvent = () => {
    let isValid = true;

    if (!eventName.trim()) {
      setValidateEventName(false);
      isValid = false;
    }

    if (!eventType.trim()) {
      setValidateEventType(false);
      isValid = false;
    }

    if (!ledById.trim()) {
      setValidateLedBy(false);
      isValid = false;
    }

    if (!startDate.trim()) {
      isValid = false;
    }

    if (!duration.trim()) {
      setValidateDuration(false);
      isValid = false;
    }

    if (repeatDays.length > 0 && !repeatMonth && !repeatWeek) {
      Alert.fire({
        title: "Selection Required!",
        text: "Please select either 'Repeat for a Week' or 'Repeat for a Month' when choosing weekdays.",
        icon: "warning",
        confirmButtonText: "Okay",
      });
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    Alert.fire({
      title: "Create this event?",
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: "Go Ahead!",
      denyButtonText: "Nope, Wait",
    }).then(({ value }) => {
      if (value) {
        dispatch(
          createEvent({
            eventName,
            eventType,
            ledById,
            start,
            duration,
            eventLink,
            repeat: repeatMonth ? "month" : repeatWeek ? "week" : false,
            repeatDays,
            participants: selectedUsers.map((item) => item.userId),
          })
        );
        history.push("/event");
      }
    });
  };

  const handleEditEvent = () => {
    let isValid = true;

    if (!eventName.trim()) {
      setValidateEventName(false);
      isValid = false;
    }

    if (!eventType.trim()) {
      setValidateEventType(false);
      isValid = false;
    }

    if (!ledById.trim()) {
      setValidateLedBy(false);
      isValid = false;
    }

    if (!startDate.trim()) {
      isValid = false;
    }

    if (!duration) {
      setValidateDuration(false);
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    Alert.fire({
      title: "Edit this event?",
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: "Go Ahead!",
      denyButtonText: "Nope, Wait",
    }).then(({ value }) => {
      if (value) {
        dispatch(
          editEvent({
            eventId,
            eventName,
            eventType,
            ledById,
            start,
            duration,
            eventLink,
            participants: selectedUsers.map((item) => item.userId),
          })
        );
        history.push("/event");
      }
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-9">
                  <EventItemWrapper
                    labelName={"Event Name"}
                    labelId={"event-name"}
                  >
                    <input
                      type="text"
                      className="form-control"
                      value={eventName}
                      onChange={(e) => {
                        setEventName(e.target.value);
                      }}
                      onBlur={(e) => {
                        if (e.target.value === "") {
                          setValidateEventName(false);
                        } else {
                          setValidateEventName(true);
                        }
                      }}
                      id="event-name"
                      placeholder="Event Name"
                      autoComplete="off"
                    />
                    {validateEventName === false ? (
                      <div className="text-danger">
                        Please give event a name.
                      </div>
                    ) : null}
                  </EventItemWrapper>

                  <EventItemWrapper
                    labelName={"Event Type"}
                    labelId="event-type"
                  >
                    <select
                      className="form-select"
                      id="event-type"
                      value={eventType}
                      onChange={(e) => {
                        const selectedIndex = e.target.options.selectedIndex;
                        setEventType(e.target.value);
                        const offeringTypeId =
                          e.target.options[selectedIndex].getAttribute("a-key");
                        setRoleId(map.get(offeringTypeId));
                        setEventTypeId(offeringTypeId);
                      }}
                      placeholder="Select Event Type"
                      onBlur={(e) => {
                        if (e.target.value === "Select Event Type") {
                          setValidateEventType(false);
                        } else {
                          setValidateEventType(true);
                        }
                      }}
                    >
                      <option value="" disabled selected>
                        Select Event Type
                      </option>
                      {offeringType
                        ? offeringType.map((offer) => {
                            return (
                              <option a-key={offer.offeringTypeId}>
                                {offer.offeringTypeName}
                              </option>
                            );
                          })
                        : null}
                    </select>
                    {validateEventType === false && (
                      <div className="text-danger">
                        Please select event type
                      </div>
                    )}
                  </EventItemWrapper>

                  <EventItemWrapper labelName={"Led By"} labelId={"led-by"}>
                    <select
                      className="form-select disabledbutton"
                      id="led-by"
                      value={ledBy}
                      disabled={eventType === "" ? true : false}
                      onChange={(e) => {
                        handleLedBy(e);
                      }}
                      onBlur={(e) => {
                        if (e.target.value === "Select Event Lead") {
                          setValidateLedBy(false);
                        } else {
                          setValidateLedBy(true);
                        }
                      }}
                    >
                      <option value="" disabled selected>
                        Select Event Lead
                      </option>
                      {provider
                        ? provider.map((item) => {
                            if (item.roleId === roleId) {
                              return (
                                <option key={item.userId}>
                                  {item.name} - {item.roleName} - {item.userId}
                                </option>
                              );
                            } else {
                              return "";
                            }
                          })
                        : null}
                    </select>
                    {validateLedBy === false && (
                      <div className="text-danger">
                        Please select event lead
                      </div>
                    )}
                  </EventItemWrapper>

                  <EventItemWrapper
                    labelName={"Start Date"}
                    labelId={"start-date"}
                  >
                    <input
                      disabled={ledBy === "" ? true : false}
                      className="form-control"
                      id="date"
                      type="date"
                      name="date"
                      min={moment(new Date()).format("YYYY-MM-DD")}
                      value={startDate}
                      onChange={(e) => {
                        setStartDate(e.target.value);
                      }}
                    />
                  </EventItemWrapper>

                  <EventItemWrapper labelName={"Duration"} labelId={"duration"}>
                    <select
                      className="form-select"
                      id="duration"
                      disabled={startDate === "" ? true : false}
                      value={duration}
                      onChange={(e) => setDuration(e.target.value)}
                      onBlur={(e) => {
                        if (
                          e.target.value === "Select Event Duration in Minutes"
                        ) {
                          setValidateDuration(false);
                        } else {
                          setValidateDuration(true);
                        }
                      }}
                    >
                      <option value="" disabled selected>
                        Select Event Duration in Minutes
                      </option>
                      <option>15</option>
                      <option>30</option>
                      <option>45</option>
                      <option>60</option>
                    </select>
                    {validateDuration === false && (
                      <div className="text-danger">
                        Please select event duration in minutes
                      </div>
                    )}
                  </EventItemWrapper>

                  <EventItemWrapper
                    labelName={"Start Time"}
                    labelId={"start-time"}
                  >
                    <input
                      className="form-control"
                      id="start-time"
                      type="time"
                      name="time"
                      value={startTime}
                      disabled={duration === "" ? true : false}
                      onChange={(e) => {
                        setStartTime(e.target.value);
                      }}
                    />

                    {/* {isLoading && (
                      <div className="text-warning">Checking slot.....</div>
                    )} */}

                    {isObjectAndNotNull(payload) && (
                      <div className="mt-2">
                        <span
                          style={
                            payload?.isAvailable
                              ? { color: Colors.primarygreen }
                              : { color: Colors.error }
                          }
                        >
                          {payload?.isAvailable ? "Available" : "Unavailable"}
                        </span>

                        {!payload?.isAvailable &&
                          isArrayAndNotNull(payload?.availableSlots) && (
                            <div className="row mt-1">
                              <div className="col-3">Available Range</div>

                              <div className="col-9">
                                <div className="inline-row">
                                  {payload?.availableSlots.map((slot, i) => (
                                    <div
                                      className="badgeWrapper"
                                      key={i}
                                      style={{
                                        backgroundColor: slot?.isTentative
                                          ? Colors.tentative_bg
                                          : Colors.primarygreen_bg,
                                      }}
                                    >
                                      <span className="badgeText">
                                        {slot?.start} - {slot?.end}
                                      </span>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                    )}
                  </EventItemWrapper>

                  {!["OFRT006", "OFRT007", "OFRT010"].includes(eventTypeId) && (
                    <EventItemWrapper
                      labelName={"Event Link"}
                      labelId={"event-link"}
                    >
                      <input
                        type="text"
                        className="form-control"
                        value={eventLink}
                        onChange={(e) => {
                          setEventLink(e.target.value);
                        }}
                        onBlur={(e) => {
                          if (
                            e.target.value === "" &&
                            !["OFRT006", "OFRT007", "OFRT010"].includes(
                              eventTypeId
                            )
                          ) {
                            setValidateEventLink(false);
                          } else {
                            setValidateEventLink(true);
                          }
                        }}
                        id="event-link"
                        placeholder="Event Link"
                        autoComplete="off"
                      />
                      {validateEventLink === false && (
                        <div className="text-danger">
                          Please enter event link.
                        </div>
                      )}
                    </EventItemWrapper>
                  )}

                  <div className="row">
                    <div
                      className="justify-content-center d-flex"
                      style={{ marginTop: "1.75rem" }}
                    >
                      <button
                        type="submit"
                        className="btn btn-success"
                        onClick={() => {
                          eventId ? handleEditEvent() : handleCreateEvent();
                        }}
                      >
                        {eventId ? "Edit Event" : "Create Event"}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  {eventId ? null : renderRepeat()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-9">
                  <div className="row">
                    <label htmlFor="zoom-link" className="col-2 col-form-label">
                      Add Participant
                    </label>
                    <div className="col-9">
                      <CreatableSelect
                        options={options}
                        isSearchable
                        placeholder="Select Participants"
                        onChange={handleChange}
                        value={selectedOption}
                        isClearable
                        formatCreateLabel={(inputValue) =>
                          "Search " + inputValue
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <button
                    type="submit"
                    className="btn btn-info w-100"
                    onClick={handleAddParticipant}
                  >
                    Add Participant
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UserTable
        users={selectedUsers}
        handleDeleteParticipant={handleDeleteParticipant}
        showActions={true}
      />
    </>
  );
};

export default EventForm;
