import { apiRequest,API_SUCCESS,API_ERROR } from "../../../actions/api"
import {ROOT_URL} from '../../../config/'

import Alert from "sweetalert2";
import { getAllSKUs, setAllSKUs, SKU, SKUS, SKUS_GET, SKU_STAUTS_UPDATE, SKU_GET, setSku, SKU_UPDATE, SKU_DELETE, SKU_CREATE } from "../../../actions/SKU";

export const SKUMiddleware = ({dispatch}) => next => (action) =>{
    next(action)
    switch(action.type){
        case SKUS_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/skus`,
                method:'post',
                feature:SKUS
            }))
            break;

        case SKU_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/sku`,
                method:'get',
                feature:SKU
            }))
            break;

        case SKU_CREATE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/createSku`,
                method:'post',
                feature:SKU
            }))
            break;
        case SKU_UPDATE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/updateSku`,
                method:'put',
                feature:SKU
            }))
            break;

        case SKU_DELETE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/deleteSku`,
                method:'delete',
                feature:SKU
            }))
            break;

        case `${SKU} ${API_SUCCESS}`:
            if(action.message === 'Updated SKU'){
                Alert.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'SKU Updated',
                    showConfirmButton: false,
                    timer: 1500
                })
                dispatch(getAllSKUs({}))
            }
            else if(action.message === 'Created SKU'){
                Alert.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'SKU Created',
                    showConfirmButton: false,
                    timer: 1500
                })
                dispatch(getAllSKUs({}))
            }
            else if(action.message === 'Deleted SKU')
            {
                Alert.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'SKU DELETED',
                    showConfirmButton: false,
                    timer: 1500
                })
                dispatch(getAllSKUs({}))
            }
            else{
                dispatch(setSku(action.payload))
            }
            break;
        case `${SKU} ${API_ERROR}`:
            if(action.message === 'Could Not Create SKU')
            {
                Alert.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Could Not Create SKU',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
            break;
        case `${SKUS} ${API_SUCCESS}`:
                dispatch(setAllSKUs(action.payload))
                break;
        case `${SKUS} ${API_ERROR}`:
            break;
        case SKU_STAUTS_UPDATE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/skuStatusUpdate`,
                method:'put',
                feature:SKU
            }))
            break;
        case `${SKU} ${API_SUCCESS}`:
                dispatch(getAllSKUs({}))    
            break;
        case `${SKU} ${API_ERROR}`:
            Alert.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Event Could Not Be Created',
                showConfirmButton: false,
                timer: 1500
              })
            break;
        default: break
    }
}

