import { DOCTOR_EXPERT_SET,DOCTOR_CONNECT_WITH_EXPERT_SET, DOCTOR_EXPERT_GET, DOCTOR_CONNECT_WITH_EXPERT, DOCTOR_CONNECT_WITH_EXPERT_RESET } from "../../middlewares/types"

const initialState = {
    doctorExpert: null,
    doctorExpertconnect: null,
    isLoading: false,
  };

export const doctorFacingReducer = (state=initialState,action) =>{
    switch(action.type){
        case DOCTOR_EXPERT_GET:
              return { ...state, isLoading: true };
        case DOCTOR_EXPERT_SET:
            return {...state, doctorExpert:action.payload, isLoading: false }
        case DOCTOR_CONNECT_WITH_EXPERT:
            return { ...state, isLoading: true };    
        case DOCTOR_CONNECT_WITH_EXPERT_SET:
            return {...state, doctorExpertconnect:action.payload, isLoading: false }
            case DOCTOR_CONNECT_WITH_EXPERT_RESET:
                return initialState          
        default: return state
    }
}