export const EXPERTS = `[EXPERTS]`;
export const EXPERT = `[EXPERT]`;
export const EXPERT_DETAILS = `[EXPERT_DETAILS]`;
export const EXPERT_STATUS = `[EXPERT_STATUS]`;

export const EXPERT_STATUS_UPDATE = `[EXPERT_STATUS] UPDATE`;

export const SPEC = `[SPEC]`;

export const SPEC_GET = `${SPEC} GET`
export const SPEC_SET = `${SPEC} SET`

export const EXPERTS_GET = `${EXPERTS} GET`;
export const EXPERTS_SET = `${EXPERTS} SET`;

export const EXPERT_DETAILS_GET = `${EXPERT_DETAILS} GET`;
export const EXPERT_DETAILS_SET = `${EXPERT_DETAILS} SET`;

export const EXPERT_CREATE = `${EXPERT} CREATE`;
export const EXPERT_UPDATE = `${EXPERT} UPDATE`;

export const getAllExpert = ({ page, pageSize, nameSearch }) => ({
    type: EXPERTS_GET,
    payload: { page, pageSize, nameSearch }
})

export const setAllExpert = (data) => ({
    type: EXPERTS_SET,
    payload: data
})

export const createExpert = (data) => ({
    type: EXPERT_CREATE,
    payload: data
})

export const getSpec = () => ({
    type: SPEC_GET,
    payload: {}
})

export const setSpec = (data) => ({
    type: SPEC_SET,
    payload: data
})

export const getExpertDetails = (data) => ({
    type: EXPERT_DETAILS_GET,
    payload: data
})

export const setExpertDetails = (data) => ({
    type: EXPERT_DETAILS_SET,
    payload: data
})

export const updateExpert = (data) => ({
    type: EXPERT_UPDATE,
    payload: data
})

export const updateExpertStatus = (data) => ({
    type: EXPERT_STATUS_UPDATE,
    payload: data
})