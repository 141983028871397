export const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
};

export const throttle = (func, limit) => {
  let lastCall = 0;
  return (...args) => {
    const now = Date.now();
    if (now - lastCall >= limit) {
      lastCall = now;
      func(...args);
    }
  };
};

export const isNullOrUndefined = (myValue) => {
  return !(myValue !== null && myValue !== undefined);
};

export const isObjectAndNotNull = (myValue) => {
  return (
    myValue !== null &&
    myValue !== undefined &&
    typeof myValue === "object" &&
    Object.keys(myValue).length > 0
  );
};

export const isArrayAndNotNull = (myValue) => {
  return myValue !== null && Array.isArray(myValue) && myValue.length > 0;
};
