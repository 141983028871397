import {
  USER_BOOKINGS_SET,
  USER_SET,
  USER_BOOKINGS_PERIOD_LOGS_SET,
  USER_BOOKINGS_WEBINAR_LOGS_SET,
  USER_BOOKINGS_SYMPTOM_LOGS_SET,
  USER_BOOKINGS_CONSULT_LOGS_SET,
  USER_LAB_REPORT_SET,
} from "../../actions/user";

const initState = [];
export const userReducer = (state = initState, action) => {
  switch (action.type) {
    case USER_SET:
      return { ...state, userDetails: action.payload };
    case USER_BOOKINGS_SET:
      return { ...state, userBookings: action.payload };
    case USER_BOOKINGS_PERIOD_LOGS_SET:
      return { ...state, userBookingsPeriodLogs: action.payload };
    case USER_BOOKINGS_WEBINAR_LOGS_SET:
      return { ...state, userBookingsWebinarLogs: action.payload };
    case USER_BOOKINGS_SYMPTOM_LOGS_SET:
      return { ...state, userBookingsSymptomLogs: action.payload };
    case USER_BOOKINGS_CONSULT_LOGS_SET:
      return { ...state, userBookingsConsultLogs: action.payload };
    case USER_LAB_REPORT_SET:
      return { ...state, userLabTestReport: action.payload };
    default:
      return state;
  }
};
