//Features
export const TOPICS = `[TOPICS]`
export const FEATURE_CREATE_TOPICS = `[CREATE_TOPICS]`
export const FEATURE_UPDATE_TOPICS = `[FEATURE_UPDATE_TOPICS]`
export const FEATURE_DELTE_TOPICS = `[FEATURE_DELTE_TOPICS]`
export const CONTENT_ITEMS = `[CONTENT_ITEMS]`
export const FEATURE_CREATE_CONTENT = `[FEATURE_CREATE_CONTENT]`
export const FEATURE_DELTE_CONTENT = `[FEATURE_DELTE_CONTENT]`
export const FEATURE_UPDATE_CONTENT = `[FEATURE_UPDATE_CONTENT]`


//TOPICS action types
export const TOPICS_GET  =  `${TOPICS} GET`
export const TOPICS_SET  =  `${TOPICS} SET`
export const TOPICS_SET_ERROR = `${TOPICS} SET_ERROR`

export const TOPICS_CREATE  =  `${FEATURE_CREATE_TOPICS}`
export const TOPICS_CREATE_SET  =  `${FEATURE_CREATE_TOPICS} SET`
export const TOPICS_CREATE_ERROR  =  `${FEATURE_CREATE_TOPICS} SET_ERROR`

export const TOPICS_UPDATE  =  `${FEATURE_UPDATE_TOPICS}`
export const TOPICS_UPDATE_SET  =  `${FEATURE_UPDATE_TOPICS} SET`
export const TOPICS_UPDATE_ERROR  =  `${FEATURE_UPDATE_TOPICS} SET_ERROR`

export const TOPICS_DELTE  =  `${FEATURE_DELTE_TOPICS}`
export const TOPICS_DELTE_SET  =  `${FEATURE_DELTE_TOPICS} SET`
export const TOPICS_DELTE_ERROR  =  `${FEATURE_DELTE_TOPICS} SET_ERROR`

//CONTENT_ITEMS action types
export const CONTENT_ITEMS_GET  =  `${CONTENT_ITEMS} GET`
export const CONTENT_ITEMS_SET  =  `${CONTENT_ITEMS} SET`
export const CONTENT_ITEMS_SET_ERROR = `${CONTENT_ITEMS} SET_ERROR`

export const CONTENT_CREATE_REQ  =  `${FEATURE_CREATE_CONTENT} GET`
export const CONTENT_CREATE_SET  =  `${FEATURE_CREATE_CONTENT} SET`
export const CONTENT_CREATE_SET_ERROR = `${FEATURE_CREATE_CONTENT} SET_ERROR`

export const CONTENT_UPDATE_REQ  =  `${FEATURE_UPDATE_CONTENT} GET`
export const CONTENT_UPDATE_SET  =  `${FEATURE_UPDATE_CONTENT} SET`
export const CONTENT_UPDATE_SET_ERROR = `${FEATURE_UPDATE_CONTENT} SET_ERROR`

export const CONTENT_DELETE_REQ  =  `${FEATURE_DELTE_CONTENT} GET`
export const CONTENT_DELETE_SET  =  `${FEATURE_DELTE_CONTENT} SET`
export const CONTENT_DELETE_SET_ERROR = `${FEATURE_DELTE_CONTENT} SET_ERROR`

//TOPICS action creators
export const createContent = (data) => ({
    type: CONTENT_CREATE_REQ,
    payload: data
})

export const deleteContent = (data) => ({
    type: CONTENT_DELETE_REQ,
    payload: data
})

export const updateContent = (data) => ({
    type: CONTENT_UPDATE_REQ,
    payload: data
})

export const createTopic = (data) => ({
    type: TOPICS_CREATE,
    payload: data
})

export const deleteTopic = (data) => ({
    type: TOPICS_DELTE,
    payload: data
})

export const updateTopic = (data) => ({
    type: TOPICS_UPDATE,
    payload: data
})

export const getTopics = (data) => ({
    type: TOPICS_GET,
    payload: data
})

export const setTopics = (data) => ({
    type: TOPICS_SET,
    payload: data
})

export const setTopicsError = (data) => ({
    type: TOPICS_SET_ERROR,
    payload: data
})

//CONTENT_ITEMS action creators
export const getContentItems = ({page=0, pageSize =20,title=''}) => ({
    type: CONTENT_ITEMS_GET,
    payload: {page, pageSize,title}
})

export const setContentItems = (data) => ({
    type: CONTENT_ITEMS_SET,
    payload: data
})

export const setContentItemsError = (data) => ({
    type: CONTENT_ITEMS_SET_ERROR,
    payload: data
})