// SKU
export const GET_SKUS_LIST = `GET_SKUS_LIST`;
export const SET_SKUS_LIST = `SET_SKUS_LIST`;
// Event Types
export const GET_EXPERT_AVAILABILITY = "GET_EXPERT_AVAILABILITY";
export const SET_EXPERT_AVAILABILITY = "SET_EXPERT_AVAILABILITY";
export const SET_EXPERT_AVAILABILITY_ERROR = "SET_EXPERT_AVAILABILITY_ERROR";
export const RESET_EXPERT_AVAILABILITY = "RESET_EXPERT_AVAILABILITY";
// DFT
export const DOCTOR_EXPERT_GET = `DOCTOR_EXPERT_GET`;
export const DOCTOR_EXPERT_SET = `DOCTOR_EXPERT_SET`;
export const DOCTOR_CONNECT_WITH_EXPERT = `DOCTOR_CONNECT_WITH_EXPERT`;
export const DOCTOR_CONNECT_WITH_EXPERT_SET = `DOCTOR_CONNECT_WITH_EXPERT_SET`;
export const DOCTOR_CONNECT_WITH_EXPERT_RESET = `DOCTOR_CONNECT_WITH_EXPERT_RESET`;