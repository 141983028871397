/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../navbar";
import { getAllExpert, updateExpertStatus } from "../../actions/expert";
import { allExpertsSelector } from "../../selector/expert";
import Toggle from "react-toggle";
import "./toggle.css";
import CommonTableContainer from "../common/PaginationTable";

const Action = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userId, status } = props?.data?.value;

  return (
    <div className="col-3">
      <i
        data-bs-toggle="modal"
        data-bs-target="#create-payments-plan-modal"
        className="mdi mdi-pencil text-warning font-18"
        onClick={() => history.push(`/updateExpert/${userId}`)}
      ></i>
      <i
        className="dripicons-clipboard text-info text-primary font-18 mx-2"
        onClick={() => {
          history.push(`/expertDetails/${userId}`);
        }}
      ></i>
      <Toggle
        defaultChecked={status === 1}
        onChange={() => dispatch(updateExpertStatus({ userId, status }))}
      />
    </div>
  );
};

const Name = (props) => {
  const userDetails = props?.data?.value; // Ensure userDetails exists

  if (!userDetails) return <p>No Data</p>; // Handle missing data gracefully

  return (
    <div
      className="d-flex align-items-center"
      style={{ gap: "10px", whiteSpace: "nowrap" }}
    >
      <img
        src={userDetails.profileImage || "default-image-url.jpg"} // Fallback image
        alt="Profile"
        className="rounded-circle"
        style={{
          width: "50px",
          height: "50px",
          objectFit: "cover",
          borderRadius: "50%",
        }}
      />
      <p className="m-0">{userDetails.name || "Unknown"}</p>
    </div>
  );
};

const ExpertList = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // Pagination state
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [nameSearch, setNameSearch] = useState("");
  const [debouncedNameSearch, setDebouncedNameSearch] = useState(nameSearch);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedNameSearch(nameSearch);
    }, 500);

    return () => clearTimeout(timer);
  }, [nameSearch]);

  useEffect(() => {
    const nameSearch = debouncedNameSearch;
    dispatch(getAllExpert({ page, pageSize, nameSearch }));
  }, [dispatch, page, pageSize, debouncedNameSearch]);

  const allExperts = useSelector((state) => allExpertsSelector(state));

  const data =
    allExperts?.expertData?.length > 0
      ? allExperts.expertData.map((item) => ({
          id: item.userId,
          userDetails: {
            name: item.name || "Unknown",
            profileImage: item.profileImage || "",
          },
          spec: item.spec || "N/A",
          rate: item.rate ? `₹ ${item.rate}` : "N/A",
          action: { userId: item.userId, status: item.status },
        }))
      : [];

  const columns = [
    {
      headerName: "Name",
      field: "userDetails",
      renderCell: (params) => <Name data={params} />,
      width: 250,
      headerAlign: "center",
    },
    {
      headerName: "Spec",
      field: "spec",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      headerName: "Rate",
      field: "rate",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      headerName: "Action",
      field: "action",
      renderCell: (params) => <Action data={params} />,
      width: 200,
      headerAlign: "center",
    },
  ];
  const onPageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <Navbar pageTitle={"All Experts"}>
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="tab-content">
              <div className="tab-pane show active">
                <div className="col d-flex justify-content-between px-3">
                  <input
                    type="text"
                    id="filter-text-box"
                    placeholder="Type a keyword..."
                    className="gridjs-input gridjs-search-input mb-2"
                    value={nameSearch}
                    onChange={(e) => setNameSearch(e.target.value)}
                  />
                  <button
                    className="btn btn-success"
                    style={{ width: "200px", height: "45px" }}
                    onClick={() => history.push("/createExpert")}
                  >
                    Create Expert
                  </button>
                </div>
                <CommonTableContainer
                  rows={data}
                  columns={columns}
                  pageSize={10}
                  currentPage={page}
                  totalRecords={allExperts.totalRecords || 0}
                  onPageChange={onPageChange}
                  rowKey="id"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Navbar>
  );
};

export default ExpertList;
