import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { paymentsPlanDetailsSelector } from "../../selector/paymentsPlan";
import Navbar from "../navbar";
import { getAllUsers } from "../../actions/users";
import { usersSelector } from "../../selector/users";
import { getPlans } from "../../actions/plan";
import { planSelector } from "../../selector/plan";
import SelectSearch, { fuzzySearch } from "react-select-search";
import {
  getPaymentsPlanDetails,
  createPaymentsPlan,
  updatePaymentsPlan,
} from "../../actions/paymentsPlan";
import { getHealthCoach } from "../../actions/users";
import { healthCoachListSelector } from "../../selector/users";

const CreatePaymentRecord = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const orderId = props.match.params.orderId;

  useEffect(() => {
    if (orderId) {
      dispatch(getPaymentsPlanDetails(orderId));
    }
    dispatch(getHealthCoach());
  }, [dispatch, orderId]);

  const payment = useSelector((state) => paymentsPlanDetailsSelector(state));
  const healthCoachList = useSelector((state) =>
    healthCoachListSelector(state)
  );

  const [userId, setUserId] = useState(payment ? payment.userId : "");
  const [name, setName] = useState(payment ? payment.name : "");
  const [email, setEmail] = useState(payment ? payment.email : "");
  const [callingNumber, setCallingNumber] = useState(
    payment ? payment.callingNumber : ""
  );
  const [whatsappNumber, setWhatsappNumber] = useState(
    payment ? payment.whatsappNumber : ""
  );
  const [planId, setPlanId] = useState(payment ? payment.planId : "");
  const [promoCode, setPromoCode] = useState(payment ? payment.promoCode : "");
  const [amount, setAmount] = useState(payment ? payment.amount : "");
  const [paymentGateway, setPaymentGateway] = useState(
    payment ? payment.paymentGateway : ""
  );
  const [healthCoach, setHealthCoach] = useState("");

  useEffect(() => {
    if (orderId && payment) {
      setUserId(payment ? payment.userId : "");
      setName(payment ? payment.name : "");
      setEmail(payment ? payment.email : "");
      setCallingNumber(payment ? payment.callingNumber : "");
      setWhatsappNumber(payment ? payment.whatsappNumber : "");
      setPlanId(payment ? payment.planId : "");
      setPromoCode(payment ? payment.promoCode : "");
      setAmount(payment ? payment.amount : "");
      setPaymentGateway(payment ? payment.paymentGateway : "");
      setHealthCoach(payment.assignedTo);
    }
  }, [payment, orderId]);

  useEffect(() => {
    dispatch(getAllUsers({}));
    dispatch(getPlans());
  }, [dispatch]);

  const { users: allUsers } = useSelector((state) => usersSelector(state));
  const allPlans = useSelector((state) => planSelector(state));

  const usersOptions = allUsers
    ? allUsers.map((user) => ({
        name: `${user.firstName} ${user.lastName} - ${user.userId}`,
        value: user.userId,
      }))
    : [];

  const planMap = new Map();

  if (allPlans.length !== 0) {
    allPlans.forEach((plan) => {
      planMap.set(plan.planId, plan.planName);
    });
  }

  const handlePlanSubmit = (e) => {
    e.preventDefault();

    if (orderId) {
      dispatch(
        updatePaymentsPlan({
          orderId,
          userId,
          name,
          email,
          callingNumber,
          whatsappNumber,
          planId,
          promoCode,
          amount,
          paymentGateway,
          assignedTo: healthCoach,
        })
      );
    } else {
      dispatch(
        createPaymentsPlan({
          userId,
          name,
          email,
          callingNumber,
          whatsappNumber,
          planId,
          promoCode,
          amount,
          paymentGateway,
          assignedTo: healthCoach,
        })
      );
    }
    history.push("/paymentsPlan");
  };

  useEffect(() => {
    if (!orderId) {
      const user =
        allUsers.length !== 0
          ? allUsers.filter((user) => user.userId === userId)
          : [];

      setName(user.length !== 0 ? user[0].firstName : "");
      setEmail(user.length !== 0 ? user[0].email : "");
      setCallingNumber(user.length !== 0 ? user[0].callingNumber : "");
      setWhatsappNumber(user.length !== 0 ? user[0].whatsappNumber : "");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userId, orderId]);

  return (
    <Navbar
      pageTitle={orderId ? "Update Payment Record" : `Create Payment Record`}
    >
      <div>
        <div className="row">
          <div className="col-8">
            <form className="form-horizontal">
              {!orderId ? (
                <div className="row mb-4">
                  <label hmtlFor="userName" className="col-2 col-form-label">
                    User
                  </label>
                  <div className="col-10 w-5">
                    <SelectSearch
                      options={usersOptions}
                      value={userId}
                      search
                      filterOptions={fuzzySearch}
                      emptyMessage="Not Found"
                      placeholder="Select User"
                      onChange={(e) => setUserId(e)}
                    />
                  </div>
                </div>
              ) : (
                <div></div>
              )}

              <div className="row mb-4">
                <label htmlFor="name" className="col-2 col-form-label">
                  Name
                </label>
                <div className="col-10">
                  <input
                    className="form-control"
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    placeholder="Name"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label htmlFor="email" className="col-2 col-form-label">
                  Email
                </label>
                <div className="col-10">
                  <input
                    className="form-control"
                    id="email"
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    placeholder="Email"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <label htmlFor="callingNumber" className="col-2 col-form-label">
                  Calling Number
                </label>

                <div className="col-10">
                  <input
                    className="form-control"
                    type="text"
                    id="name"
                    value={callingNumber}
                    onChange={(e) => {
                      setCallingNumber(e.target.value);
                    }}
                    placeholder="Calling Number"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label htmlFor="whatsapp" className="col-2 col-form-label">
                  Whatsapp Number
                </label>

                <div className="col-10">
                  <input
                    className="form-control"
                    type="text"
                    id="name"
                    value={whatsappNumber}
                    onChange={(e) => {
                      setWhatsappNumber(e.target.value);
                    }}
                    placeholder="Whatsapp Number"
                  />
                </div>
              </div>

              <div className="row mb-4">
                <label hmtlFor="planName" className="col-2 col-form-label">
                  Plan Name
                </label>

                <div className="col-10">
                  <select
                    className="form-select"
                    id="paymentGateway"
                    value={`${planMap.get(planId)}-${planId}`}
                    onChange={(e) => {
                      const value = e.target.value.split("-");
                      setPlanId(value[value.length - 1]);
                    }}
                  >
                    <option value="" disabled selected>
                      Select Plan
                    </option>
                    {allPlans.length !== 0
                      ? allPlans.map((plan) => (
                          <option>{`${plan.planName}-${plan.planId}`}</option>
                        ))
                      : null}
                  </select>
                </div>
              </div>

              <div className="row mb-4">
                <label htmlFor="amount" className="col-2 col-form-label">
                  Amount
                </label>

                <div className="col-10">
                  <input
                    className="form-control"
                    type="text"
                    id="amount"
                    value={amount}
                    onChange={(e) => {
                      setAmount(e.target.value);
                    }}
                    placeholder="Amount"
                  />
                </div>
              </div>

              <div className="row mb-4">
                <label
                  htmlFor="paymentGateway"
                  className="col-2 col-form-label"
                >
                  Payment Gateway
                </label>

                <div className="col-10">
                  <select
                    className="form-select"
                    id="paymentGateway"
                    value={paymentGateway}
                    onChange={(e) => {
                      setPaymentGateway(e.target.value);
                    }}
                  >
                    <option value="" disabled selected>
                      Select Payment Gateway
                    </option>
                    <option>Razorpay</option>
                    <option>Paytm</option>
                    <option>Simpl</option>
                  </select>
                </div>
              </div>

              <div className="row mb-4">
                <label htmlFor="promoCode" className="col-2 col-form-label">
                  Promo Code
                </label>

                <div className="col-10">
                  <input
                    className="form-control"
                    type="text"
                    id="promoCode"
                    value={promoCode}
                    onChange={(e) => {
                      setPromoCode(e.target.value);
                    }}
                    placeholder="Promo Code"
                  />
                </div>
              </div>

              <div className="row mb-4">
                <label className="col-2 col-form-label">
                  Assign Health Coach
                </label>

                <div className="col-10">
                  <select
                    className="form-select"
                    value={healthCoach}
                    onChange={(e) => setHealthCoach(e.target.value)}
                  >
                    <option value="" disabled selected>
                      Assign health coach
                    </option>
                    <option value="Marketing">Marketing</option>
                    {healthCoachList
                      ? healthCoachList.map((item) => (
                          <option>{item.name}</option>
                        ))
                      : ""}
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="justify-content-center d-flex">
                  <div>&nbsp;</div>
                  <button
                    className="btn btn-success"
                    type="submit"
                    onClick={(e) => {
                      handlePlanSubmit(e);
                    }}
                  >
                    {orderId ? "Update" : "Create"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Navbar>
  );
};

export default CreatePaymentRecord;
