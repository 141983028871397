export const userSelector = (state) =>
  state.userReducer.userDetails ? state.userReducer.userDetails[0] : {};
export const userBookingsSelector = (state) =>
  state.userReducer.userBookings ? state.userReducer.userBookings : [];
export const userBookingsPeriodLogsSelector = (state) =>
  state.userReducer.userBookingsPeriodLogs
    ? state.userReducer.userBookingsPeriodLogs
    : [];
export const userBookingsWebinarLogsSelector = (state) =>
  state.userReducer.userBookingsWebinarLogs
    ? state.userReducer.userBookingsWebinarLogs
    : [];
export const userBookingsSymptomLogsSelector = (state) =>
  state.userReducer.userBookingsSymptomLogs
    ? state.userReducer.userBookingsSymptomLogs
    : [];
export const userBookingsConsultLogsSelector = (state) =>
  state.userReducer.userBookingsConsultLogs
    ? state.userReducer.userBookingsConsultLogs
    : [];
export const userLabTestReportSelector = (state) =>
  state.userReducer.userLabTestReport
    ? state.userReducer.userLabTestReport
    : [];
