export const SKUS = `[SKUS]`
export const SKU = `[SKU]`

export const SKU_GET = `${SKU} GET`
export const SKU_SET = `${SKU} SET`
export const SKU_CREATE = `${SKU} CREATE`
export const SKU_DELETE = `${SKU} DELETE`
export const SKU_UPDATE = `${SKU} UPDATE`
export const SKUS_GET = `${SKUS} GET`
export const SKUS_SET = `${SKUS} SET`
export const SKU_STAUTS_UPDATE = `${SKU} STATUS UPDATE`

export const getAllSKUs = ({ page, pageSize, search }) => ({
    type: SKUS_GET,
    payload: { page, pageSize, search }
})

export const setAllSKUs = (data) => ({
    type: SKUS_SET,
    payload: data
})

export const updateSkuStatus = (data) => ({
    type: SKU_STAUTS_UPDATE,
    payload: data
})

export const getSku = (data) => ({
    type: SKU_GET,
    payload: data
})

export const setSku = (data) => ({
    type: SKU_SET,
    payload: data   
})

export const updateSku = (data) => ({
    type: SKU_UPDATE,
    payload: data
})

export const deleteSku = (data) => ({
    type: SKU_DELETE,
    payload: data
})

export const createSku = (data) => ({
    type: SKU_CREATE,
    payload: data
})