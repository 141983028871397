const Colors = {
  primarygreen: "#008037",
  primarygreen_bg: "#00803726",
  tentative: "#F3A114",
  tentative_bg: "#F3A11426",
  error: "#EB1415",
  error_bg: "#EB141526",
};

export default Colors;
