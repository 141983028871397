import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../../navbar";
import { _ } from "gridjs-react";
import {
  activateCoupons,
  deactivateCoupons,
  getCoupons,
} from "../../../../actions/promoCodes";
import { couponsSelector } from "../../../../selector/promoCodes/coupons";
import moment from "moment";
import { useHistory } from "react-router-dom";
import CommonTableContainer from "../../../common/PaginationTable";
import {
  PAGE_SIZE,
  SEARCH_COUPON_PLACEHOLDER,
} from "../../../../constant/userListingConstants";

const Table = () => {
  const [currentPage, setCurrentPage] = useState(0);

  const history = useHistory();
  const dispatch = useDispatch();
  const page = 0;
  const search = "";

  useEffect(() => {
    dispatch(
      getCoupons({
        page,
        search,
      })
    );
  }, [dispatch]);

  const coupons = useSelector((state) => couponsSelector(state));

  const handleActivate = (couponId, page) => {
    dispatch(activateCoupons({ couponId }));
    fetchData({ page });
  };

  const handleSearch = (search) => {
    fetchData({ search });
  };

  const fetchData = ({ page = 0, search = "" } = {}) => {
    setCurrentPage(page);
    dispatch(
      getCoupons({
        page,
        search,
      })
    );
  };

  const handleDeactivate = (couponId, page) => {
    dispatch(deactivateCoupons({ couponId }));
    fetchData({ page });
  };

  const data = coupons.data
    ? coupons.data.map((coupon) => ({
        id: coupon.couponId,
        couponId: coupon.couponId,
        offerName: coupon.offerName,
        type: coupon.type,
        discount:
          coupon.type === "Discount"
            ? `${coupon.discount}%`
            : `₹ ${coupon.discount}`,
        startDate: moment(coupon.startDate).format("YYYY-MM-DD hh:mm A"),
        endDate: moment(coupon.endDate).format("YYYY-MM-DD hh:mm A"),
        totalUsage: coupon.totalUsage,
        maxUsagePerUser: coupon.maxUsagePerUser,
        maxUsage: coupon.maxUsage,
        status: coupon.status,
      }))
    : [];

  const columns = [
    { field: "couponId", headerName: "Coupon Code", width: 200 },
    { field: "offerName", headerName: "Offer Name", width: 150 },
    { field: "type", headerName: "Type", width: 100 },
    { field: "discount", headerName: "Discount", width: 100 },
    { field: "startDate", headerName: "Starts On", width: 130 },
    { field: "endDate", headerName: "Ends On", width: 130 },
    { field: "totalUsage", headerName: "Total Usage", width: 100 },
    { field: "maxUsage", headerName: "Max Usage", width: 120 },
    { field: "maxUsagePerUser", headerName: "Max Usage Per User", width: 120 },
    {
      field: "status",
      headerName: "Actions",
      width: 190,
      align: "center",
      renderCell: (params) => {
        const coupon = params.row;

        return coupon.status === 0 ? (
          <div className="row d-flex justify-content-left">
            <button
              className="col-10 btn btn-success btn-rounded"
              onClick={() => handleActivate(coupon.couponId, currentPage)}
              style={{ marginLeft: "-30px" }}
            >
              Activate
            </button>
            <div
              className="col-2"
              onClick={() => {
                history.push(`/updateCoupon/${coupon.couponId}`);
              }}
            >
              <i className="mdi mdi-pencil text-warning font-20"></i>
            </div>
          </div>
        ) : (
          <div className="row d-flex justify-content-left">
            <button
              className="col-10 btn btn-danger btn-rounded"
              onClick={() => handleDeactivate(coupon.couponId, currentPage)}
              style={{ marginLeft: "-30px" }}
            >
              Deactivate
            </button>
            <div
              className="col-2"
              onClick={() => {
                history.push(`/updateCoupon/${coupon.couponId}`);
              }}
            >
              <i className="mdi mdi-pencil text-warning font-20"></i>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className="col-6 d-flex flex-column align-items-left mb-4">
        <label htmlFor="searchCoupon" className="col-form-label">
          Search by Coupon Code:
        </label>
        <input
          type="text"
          id="searchCoupon"
          className="form-control"
          placeholder={SEARCH_COUPON_PLACEHOLDER}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>

      <CommonTableContainer
        rows={data}
        columns={columns}
        pageSize={PAGE_SIZE}
        currentPage={currentPage}
        totalRecords={coupons.totalRecords}
        onPageChange={(page) => fetchData({ page })}
        getRowId={(row) => row.couponId}
      />
    </div>
  );
};

const Coupons = () => {
  return (
    <div>
      <Navbar pageTitle={"Coupons"}>
        <Table />
      </Navbar>
    </div>
  );
};

export default Coupons;
