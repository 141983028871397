import { apiRequest,API_SUCCESS,API_ERROR } from "../../../actions/api"
import {ROOT_URL} from '../../../config/'
import Alert from "sweetalert2";
import { COUPONS, COUPON, VOUCHER, VOUCHER_GET, setVoucherDetails, COUPON_UPDATE, VOUCHER_UPDATE, COUPON_GET, setCouponDetails, COUPONS_ACTIVATE, COUPONS_CREATE, COUPONS_DEACTIVATE, COUPONS_GET, getCoupons, setCoupons, setVouchers, VOUCHERS,VOUCHERS_CREATE, VOUCHERS_GET, getVouchers } from "../../../actions/promoCodes";

export const promoCodesMiddleware = ({dispatch}) => next => (action) => {
    next(action)
    switch(action.type){
            case COUPONS_CREATE:
                dispatch(apiRequest({
                    body:action.payload,
                    config:{},
                    url:`${ROOT_URL}/v1/createCoupons`,
                    method:'post',
                    feature:COUPONS                
                }))
                break;
            case COUPON_UPDATE:
                dispatch(apiRequest({
                    body:action.payload,
                    config:{},
                    url:`${ROOT_URL}/v1/updateCoupon`,
                    method:'put',
                    feature:COUPON               
                }))
                break;
            case VOUCHER_UPDATE:
                dispatch(apiRequest({
                    body:action.payload,
                    config:{},
                    url:`${ROOT_URL}/updateVoucher`,
                    method:'put',
                    feature:VOUCHER            
                }))
                break;
            case COUPONS_GET:
                dispatch(apiRequest({
                    body:action.payload,
                    config:{},
                    url:`${ROOT_URL}/getCoupons`,
                    method:'post',
                    feature:COUPONS                
                }))
                break;
            case COUPON_GET:
                dispatch(apiRequest({
                    body:action.payload,
                    config:{},
                    url:`${ROOT_URL}/v1/getCoupon`,
                    method:'get',
                    feature:COUPON                
                }))
                break;
            case VOUCHER_GET:
                dispatch(apiRequest({
                    body:action.payload,
                    config:{},
                    url:`${ROOT_URL}/getVoucher`,
                    method:'get',
                    feature:VOUCHER                
                }))
                break;
            case COUPONS_ACTIVATE:
                dispatch(apiRequest({
                    body:action.payload,
                    config:{},
                    url:`${ROOT_URL}/activateCoupon`,
                    method:'put',
                    feature:COUPONS                
                }))
                break;
            case COUPONS_DEACTIVATE:
                dispatch(apiRequest({
                    body:action.payload,
                    config:{},
                    url:`${ROOT_URL}/deactivateCoupon`,
                    method:'put',
                    feature:COUPONS                
                }))
                break;
            
            
            case `${COUPONS} ${API_SUCCESS}`:
                if(action.message==='Fetched All Coupons'){
                    dispatch(setCoupons(action.payload))
                }else if(action.message==='Coupon Code Activated' || action.message==='Coupon Code Deactivated'){
                    dispatch(getCoupons({}))
                    Alert.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: action.message,
                        showConfirmButton: false,
                        timer: 1500
                      })
                }
                else{
                    Alert.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: action.message,
                        showConfirmButton: false,
                        timer: 1500
                      }) 
                }
                  break;
            case `${COUPONS} ${API_ERROR}`:
                Alert.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: action.message,
                    showConfirmButton: false,
                    timer: 1500
                  })
                  break; 
            case `${COUPON} ${API_SUCCESS}`:
                if(action.message==='Coupon Updated'){
                    Alert.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: action.message,
                        showConfirmButton: false,
                        timer: 1500
                        })
                    dispatch(getCoupons({}))
                }else{
                    dispatch(setCouponDetails(action.payload))
                }
                break;
            case `${VOUCHER} ${API_SUCCESS}`:
                if(action.message==='Voucher Updated'){
                    Alert.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: action.message,
                        showConfirmButton: false,
                        timer: 1500
                        })
                    dispatch(getVouchers())
                }else{
                    dispatch(setVoucherDetails(action.payload))
                }
                break;
            case `${COUPON} ${API_ERROR}`:
                if(action.message==='Coupon could not be updated'){
                    Alert.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: action.message,
                        showConfirmButton: false,
                        timer: 1500
                        })
                }
                break;
            case `${VOUCHER} ${API_ERROR}`:
                if(action.message==='Voucher could not be updated'){
                    Alert.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: action.message,
                        showConfirmButton: false,
                        timer: 1500
                        })
                }
                break;
            case VOUCHERS_CREATE:
                dispatch(apiRequest({
                    body:action.payload,
                    config:{},
                    url:`${ROOT_URL}/v1/createVouchers`,
                    method:'post',
                    feature:VOUCHERS                
                }))
                break;
            case VOUCHERS_GET:
                dispatch(apiRequest({
                    body:action.payload,
                    config:{},
                    url:`${ROOT_URL}/getVouchers`,
                    method:'post',
                    feature:VOUCHERS                
                }))
                break;
            case `${VOUCHERS} ${API_SUCCESS}`:
                if(action.message==='Fetched All Vouchers'){
                    dispatch(setVouchers(action.payload))
                }else{
                    Alert.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: action.message,
                        showConfirmButton: false,
                        timer: 1500
                      })
                }
                  break;
            case `${VOUCHERS} ${API_ERROR}`:
                Alert.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: action.message,
                    showConfirmButton: false,
                    timer: 1500
                  })
                  break; 
                   
            default: break;
    }
}
