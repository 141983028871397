import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../../navbar";
import { getVouchers } from "../../../../actions/promoCodes";
import { vouchersSelector } from "../../../../selector/promoCodes/vouchers";
import moment from "moment";
import { useHistory } from "react-router-dom";
import CommonTableContainer from "../../../common/PaginationTable";
import {
  PAGE_SIZE,
  SEARCH_VOUCHER_PLACEHOLDER,
} from "../../../../constant/userListingConstants";

const Table = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getVouchers({ page: currentPage, search }));
  }, [dispatch, currentPage, search]);

  const vouchers = useSelector((state) => vouchersSelector(state));

  const fetchData = ({ page = 0, search = "" } = {}) => {
    setCurrentPage(page);
    setSearch(search);
    dispatch(getVouchers({ page, search }));
  };

  const handleSearch = (search) => {
    fetchData({ search });
  };

  const data = vouchers.data
    ? vouchers.data.map((voucher) => ({
        id: voucher.voucherId,
        voucherId: voucher.voucherId,
        offerName: voucher.offerName,
        type: voucher.type,
        discount:
          voucher.type === "Discount"
            ? `${voucher.discount}%`
            : `₹ ${voucher.discount}`,
        startDate: moment(voucher.startDate).format("YYYY-MM-DD hh:mm A"),
        endDate: moment(voucher.endDate).format("YYYY-MM-DD hh:mm A"),
        status: voucher.status,
      }))
    : [];

  const columns = [
    { field: "voucherId", headerName: "Voucher Code", width: 200 },
    { field: "offerName", headerName: "Offer Name", width: 150 },
    { field: "type", headerName: "Type", width: 100 },
    { field: "discount", headerName: "Discount", width: 100 },
    { field: "startDate", headerName: "Starts On", width: 130 },
    { field: "endDate", headerName: "Ends On", width: 130 },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) =>
        params.row.status === 1 ? (
          <span className="badge bg-danger">Not Availed</span>
        ) : (
          <span className="badge bg-success">Availed</span>
        ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => (
        <div
          className="col-2"
          onClick={() => {
            history.push(`/updateVoucher/${params.row.voucherId}`);
          }}
        >
          <i className="mdi mdi-pencil text-warning font-20"></i>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="col-6 d-flex flex-column align-items-left mb-4">
        <label htmlFor="searchVoucher" className="col-form-label">
          Search by Voucher Code:
        </label>
        <input
          type="text"
          id="searchVoucher"
          className="form-control"
          placeholder={SEARCH_VOUCHER_PLACEHOLDER}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>

      <CommonTableContainer
        rows={data}
        columns={columns}
        pageSize={PAGE_SIZE}
        currentPage={currentPage}
        totalRecords={vouchers.totalRecords || 0}
        onPageChange={(page) => fetchData({ page })}
        getRowId={(row) => row.voucherId}
      />
    </div>
  );
};

const Vouchers = () => {
  return (
    <div>
      <Navbar pageTitle={"Vouchers"}>
        <Table />
      </Navbar>
    </div>
  );
};

export default Vouchers;
