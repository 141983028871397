export const DOCTORS = `[DOCTORS]`
export const DOCTOR = `[DOCTOR]`
export const DOCTOR_VERIFIED = `[DOCTOR_VERIFIED]`
export const DOCTOR_ONBOARDING = `[DOCTOR_ONBOARDING]`

export const PATIENT_TO_DOCTOR = `[PATIENT_TO_DOCTOR]`;

export const DOCTOR_PATIENTS = `[DOCTOR_PATIENTS]`;
export const DOCTOR_PATIENTS_GET = `[DOCTOR_PATIENTS] GET`;
export const DOCTOR_PATIENTS_SET = `[DOCTOR_PATIENTS] SET`;

export const PATIENT_TO_DOCTOR_ASSIGN = `[PATIENT_TO_DOCTOR] ASSIGN`;

export const DOCTOR_PROFILE_PRESIGNED_URL = `[DOCTOR_PROFILE_PRESIGNED_URL]`

export const DOCTOR_PROFILE_PRESIGNED_URL_GET = `${DOCTOR_PROFILE_PRESIGNED_URL} GET`
export const DOCTOR_PROFILE_PRESIGNED_URL_SET = `${DOCTOR_PROFILE_PRESIGNED_URL} SET`

export const DOCTOR_VERIFIED_UPDATE =  `${DOCTOR_VERIFIED} UPDATE`
export const DOCTOR_ONBOARDING_UPDATE =  `${DOCTOR_ONBOARDING} UPDATE`

export const DOCTORS_GET = `${DOCTORS} GET`
export const DOCTORS_SET = `${DOCTORS} SET`

export const DOCTOR_CREATE = `${DOCTOR} CREATE`
export const DOCTOR_UPDATE = `${DOCTOR} UPDATE`
export const DOCTOR_GET = `${DOCTOR} GET`
export const DOCTOR_SET = `${DOCTOR} SET`

export const getAllDcotors = () => ({
    type: DOCTORS_GET,
    payload: {}
})

export const setAllDcotors = (data) => ({
    type: DOCTORS_SET,
    payload: data
})

export const updateDoctorVerified = (data) => ({
    type: DOCTOR_VERIFIED_UPDATE,
    payload: data
})

export const updateDoctorOnboarding = (data) => ({
    type: DOCTOR_ONBOARDING_UPDATE,
    payload: data
})

export const getDoctorProfilePresignedUrl = (data) => ({
    type: DOCTOR_PROFILE_PRESIGNED_URL_GET,
    payload: data
});

export const setDoctorProfilePresignedUrl = (data) => ({
    type: DOCTOR_PROFILE_PRESIGNED_URL_SET,
    payload: data
});

export const createDoctor = (data) => ({
    type: DOCTOR_CREATE,
    payload: data
})

export const updateDoctor = (data) => ({
    type: DOCTOR_UPDATE,
    payload: data
})

export const getDoctor = (data) => ({
    type: DOCTOR_GET,
    payload: data
})

export const setDoctor = (data) => ({
    type: DOCTOR_SET,
    payload: data
})

export const assignPatientToDoctor = (data) => ({
    type: PATIENT_TO_DOCTOR_ASSIGN,
    payload: data
})

export const getDoctorPatients = (data) => ({
    type: DOCTOR_PATIENTS_GET,
    payload: data
})

export const setDoctorPatients = (data) => ({
    type: DOCTOR_PATIENTS_SET,
    payload: data
})

