import React, { useState, useEffect } from "react";
import Navbar from "../../navbar";
import CouponsForm from "./couponsForm";
import VouchersForm from "./vouchersForm";
import { useDispatch } from "react-redux";
import { getPlans } from "../../../actions/plan";

const CreateCode = () => {
  const dispatch = useDispatch();
  const [vouchersActive, setVouchersActive] = useState("");
  const [couponsActive, setCouponsActive] = useState("active");

  const vouchersClick = () => {
    setVouchersActive("active");
    setCouponsActive("");
  };

  const couponsClick = () => {
    setVouchersActive("");
    setCouponsActive("active");
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "/assets/js/advancedForm.js";
    script.async = true;
    document.body.appendChild(script);
    setCouponsActive("active");
    dispatch(getPlans());
    return () => {
      document.body.removeChild(script);
    };
  }, [dispatch]);

  return (
    <Navbar pageTitle={"Generate Promo Codes"}>
      <ul className="nav nav-pills bg-nav-pills nav-justified mb-4">
        <li
          className="nav-item"
          onClick={() => {
            vouchersClick();
          }}
        >
          <a
            href="#home1"
            data-bs-toggle="tab"
            aria-expanded="false"
            className={`nav-link rounded-0 ${vouchersActive}`}
          >
            <i className="mdi mdi-home-variant d-md-none d-block"></i>
            <span className="d-none d-md-block">Create Vouchers</span>
          </a>
        </li>

        <li
          className="nav-item"
          onClick={() => {
            couponsClick();
          }}
        >
          <a
            href="#settings1"
            data-bs-toggle="tab"
            aria-expanded="false"
            className={`nav-link rounded-0 ${couponsActive}`}
          >
            <i className="mdi mdi-settings-outline d-md-none d-block"></i>
            <span className="d-none d-md-block">Create Coupons</span>
          </a>
        </li>
      </ul>
      {vouchersActive === "active" ? <VouchersForm /> : <CouponsForm />}
    </Navbar>
  );
};

export default CreateCode;
