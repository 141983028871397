import { GET_SKUS_LIST, SET_SKUS_LIST } from "../../middlewares/types";

const initialState = {
  skus: null,
  isLoading: false,
};

const skusReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SKUS_LIST:
      return { ...state, isLoading: true };
    case SET_SKUS_LIST:
      return { ...state, skus: action.payload, isLoading: false };
    default:
      return state;
  }
};

export default skusReducer;
