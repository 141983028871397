import React from "react";

const EventItemWrapper = ({ children, labelName, labelId }) => {
  return (
    <div className="row mb-3">
      <label htmlFor={labelId} className="col-2 col-form-label">
        {labelName}
      </label>
      <div className="col-9">{children}</div>
    </div>
  );
};

export default EventItemWrapper;
