import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Navbar from "../../navbar";
import { getAllUsers } from "../../../actions/users";
import { usersSelector } from "../../../selector/users";
import moment from "moment";
import { getPlans } from "../../../actions/plan";
import { planSelector } from "../../../selector/plan";
import CommonTableContainer from "../../common/PaginationTable"
import "./mui-grid.css";
import CustomSelect from "../../common/CustomSelect";
import {
  SEARCH_CONTACT_PLACEHOLDER,
  PLAN_NAME_PLACEHOLDER,
  ACQ_CHANNEL_PLACEHOLDER,
  FILTER_BY_OPTIONS,
  ACQ_CHANNEL_LIST,
  PAGE_SIZE,
} from "../../../constant/userListingConstants";

const Action = (props) => {
  const history = useHistory();
  const userId = props.row.action;

  return (
    <div className="row">
      <div className="col-3">
        <i
          onClick={() => {
            history.push(`/userDetails/${userId}`);
          }}
          className="mdi mdi-account text-info"
        ></i>
      </div>
      <div className="col-3">
        <i
          onClick={() => {
            history.push(`/newUser/${userId}`);
          }}
          className="mdi mdi-pencil text-warning"
        ></i>
      </div>
    </div>
  );
};

const Table = ({ filteredUsers, totalRecords, currentPage, fetchData }) => {
  const data = filteredUsers
    ? filteredUsers.map((user) => ({
        id: user.userId,
        userName:
          user.lastName !== null
            ? `${user.firstName} ${user.lastName}`
            : `${user.firstName}`,
        contact: user.callingNumber,
        planName: user.planName,
        startDate: moment(user.planStartDate).format("DD-MM-YYYY"),
        endDate: moment(user.planEndDate).format("DD-MM-YYYY"),
        remaining:
          user.planName !== "Free Plan" &&
          moment(user.planEndDate).diff(moment(), "days") > 0
            ? `${moment(user.planEndDate).diff(moment(), "days")}`
            : `∞`,
        email: user.email,
        action: user.userId,
        nutritionCoach: user.nutritionCoach,
      }))
    : [];

  const columns = [
    {
      field: "action",
      headerName: "Actions",
      renderCell: Action,
      width: 100,
    },
    { field: "userName", headerName: "Name", width: 200 },
    { field: "contact", headerName: "Contact", width: 150 },
    { field: "planName", headerName: "Current Plan", width: 200 },
    { field: "startDate", headerName: "Plan Start", width: 130 },
    { field: "endDate", headerName: "Plan End", width: 130 },
    { field: "remaining", headerName: "Remaining", width: 90 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "nutritionCoach", headerName: "Nutrition Coach", width: 150 },
    { field: "id", headerName: "User ID", width: 150 },
  ];

  return (
    <CommonTableContainer
      rows={data}
      columns={columns}
      pageSize={PAGE_SIZE}
      currentPage={currentPage}
      totalRecords={totalRecords}
      onPageChange={(page) => fetchData({ page })}
    />
  );
};

const UserListing = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const [filterBy, setFilterBy] = useState("");
  const [planName, setPlanName] = useState("");
  const [acqChannel, setAcqChannel] = useState("");
  const [contact, setContact] = useState("");
  const users = useSelector((state) => usersSelector(state));
  const plans = useSelector((state) => planSelector(state));

  useEffect(() => {
    fetchData();
    dispatch(getPlans());
  }, [dispatch]);

  const fetchData = ({
    page = 0,
    contact = "",
    planName = "",
    acqChannel = "",
  } = {}) => {
    setCurrentPage(page);
    dispatch(
      getAllUsers({
        page,
        contact,
        planName,
        acqChannel,
      })
    );
  };

  const handleSearch = (contact) => {
    setContact(contact);
    fetchData({ contact });
  };

  const handlePlanNameSelector = (plan) => {
    setPlanName(plan);
    const planName = plan.map((item) => item.value);
    fetchData({ planName });
  };

  const handleAcqChannelSelector = (channelName) => {
    setAcqChannel(channelName);
    const acqChannel = channelName.map((item) => item.value);
    fetchData({ acqChannel });
  };

  const handleClearAll = () => {
    setFilterBy("");
    setPlanName("");
    setAcqChannel("");
    setContact("");
    fetchData();
  };

  const renderFilterValue = () => {
    if (filterBy === "Plan Name") {
      const options = plans.map((plan) => ({
        label: plan.planName,
        value: plan.planName,
      }));
      return (
        <CustomSelect
          value={planName}
          options={options}
          placeholder={PLAN_NAME_PLACEHOLDER}
          onChange={(e) => handlePlanNameSelector(e)}
          isMulti={true}
        />
      );
    } else if (filterBy === "Acquisition Channel") {
      return (
        <CustomSelect
          value={acqChannel}
          options={ACQ_CHANNEL_LIST}
          placeholder={ACQ_CHANNEL_PLACEHOLDER}
          onChange={(e) => handleAcqChannelSelector(e)}
          isMulti={true}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <div>
      <Navbar pageTitle={"User Database"}>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="text-start">
                  <div className="row mb-3">
                    <div className="col-6">
                      <label htmlFor="searchContact" className="col-form-label">
                        Search by Contact:
                      </label>
                      <input
                        type="text"
                        id="searchContact"
                        className="form-control"
                        placeholder={SEARCH_CONTACT_PLACEHOLDER}
                        value={contact}
                        onChange={(e) => handleSearch(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="row">
                        <div className="col-3">
                          <label htmlFor="filterBy" className="col-form-label">
                            Filter By:
                          </label>
                        </div>
                        <div className="col-9">
                          <select
                            className="form-select"
                            id="led-by"
                            value={filterBy}
                            onChange={(e) => setFilterBy(e.target.value)}
                          >
                            {FILTER_BY_OPTIONS.map((option) => (
                              <option
                                key={option.value}
                                value={option.value}
                                disabled={option.disabled || false}
                              >
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="row">
                        <div className="col-3">
                          <label htmlFor="filterBy" className="col-form-label">
                            Filter Value:
                          </label>
                        </div>
                        <div className="col-9">{renderFilterValue()}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-1">
                    <div className="col-10">
                      <p className="header-title mt-0 mb-3">Apply Filter</p>
                    </div>
                    <div className="col-2">
                      <button
                        className="btn btn-danger w-100"
                        onClick={handleClearAll}
                      >
                        Clear All
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Table
          filteredUsers={users.users}
          totalRecords={users.totalRecords}
          currentPage={currentPage}
          fetchData={fetchData}
        />
      </Navbar>
    </div>
  );
};

export default UserListing;
