import {
  setExpertAvailability,
  setExpertAvailabilityError,
} from "../../../actions/v1/eventsAction";
import { ApiClient, ApiMethods } from "../../core/api/ApiClient";
import ApiEndpoints from "../../core/api/ApiEndpoints";
import { GET_EXPERT_AVAILABILITY } from "../../types";

export const eventsFeature =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    next(action);
    switch (action.type) {
      case GET_EXPERT_AVAILABILITY:
        try {
          const res = await ApiClient({
            method: ApiMethods.GET,
            endpoint: ApiEndpoints.expertAvailability,
            data: action.payload,
            feature: action.type,
          });

          if (res?.responseStatus?.statusCode) {
            dispatch(setExpertAvailability(res?.responseData));
          }
        } catch (error) {
          dispatch(setExpertAvailabilityError(error));
        }
        break;
      default:
        break;
    }
  };
