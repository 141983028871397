import { setSkusList } from "../../../actions/v1/skusAction";
import { ApiClient, ApiMethods } from "../../core/api/ApiClient";
import ApiEndpoints from "../../core/api/ApiEndpoints";
import { GET_SKUS_LIST } from "../../types";

export const skusFeature =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    next(action);
    switch (action.type) {
      case GET_SKUS_LIST:
        try {
          const res = await ApiClient({
            method: ApiMethods.GET,
            endpoint: ApiEndpoints.skus,
            data: action.payload,
            feature: GET_SKUS_LIST,
          });

          if (res?.responseStatus?.statusCode) {
            dispatch(setSkusList(res?.responseData));
          }
        } catch (error) {
          dispatch(setSkusList([]));
        }

        break;
      default:
        break;
    }
  };
