import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import AsyncSelect from "react-select/async";
import moment from "moment";
import { createVouchers } from "../../../actions/promoCodes";
import { getSkusList } from "../../../actions/v1/skusAction";
import { ApiClient, ApiMethods } from "../../../middlewares/core/api/ApiClient";
import ApiEndpoints from "../../../middlewares/core/api/ApiEndpoints";
import { GET_SKUS_LIST } from "../../../middlewares/types";

const VouchersForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [offerName, setOfferName] = useState("");
  const [validateOfferName, setValidateOfferName] = useState(true);

  const [voucherPrefix, setVoucherPrefix] = useState("");
  const [validateVoucherPrefix, setValidateVoucherPrefix] = useState(true);

  const [type, setType] = useState("");
  const [validateType, setValidateType] = useState(true);

  const [discount, setDiscount] = useState("");
  const [validateDiscount, setValidateDiscount] = useState(true);

  const [lifeSpan, setLifeSpan] = useState("");
  const [validateLifeSpan, setValidateLifeSpan] = useState(true);

  const [startDate, setStartDate] = useState("");
  const [validateStartDate, setValidateStartDate] = useState(true);

  const [endDate, setEndDate] = useState("");
  const [validateEndDate, setValidateEndDate] = useState(true);

  const [maxAmount, setMaxAmount] = useState("");

  const [description, setDescription] = useState("");
  const [validateDescription, setValidateDescription] = useState(true);

  const [generateMultiple, setGenerateMultiple] = useState("");
  const [validateGenerateMultiple, setValidateGenerateMultiple] =
    useState(true);

  const [skusList, setSkusList] = useState([]);
  const [selectedSkusId, setSelectedSkusId] = useState([]);
  const [skuSearchField, setSkuSearchField] = useState("");
  const [selectAllSkus, setSelectAllSkus] = useState(false);
  const [skusPagination, setSkusPagination] = useState({
    pageNo: 1,
    pageSize: 20,
    status: 1,
  });

  // fetch skus if page number is changed
  useEffect(() => {
    dispatch(getSkusList(skusPagination));
  }, [skusPagination?.pageNo]);

  const { skus, isLoading } = useSelector((state) => state.skus);

  // appending new skus to existing skus
  useEffect(() => {
    if (skus && skus?.data.length > 0) {
      setSkusList((prevSkus) => [...prevSkus, ...skus.data]);
    }
  }, [skus]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "/assets/js/advancedForm.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const onSearchGetSkus = async (searchValue) => {
    try {
      const res = await ApiClient({
        method: ApiMethods.GET,
        endpoint: ApiEndpoints.skus,
        data: { pageNo: 1, pageSize: 30, status: 1, searchValue },
        feature: GET_SKUS_LIST,
      });

      if (res?.responseStatus?.statusCode) {
        return res?.responseData?.data;
      }
    } catch (error) {
      return [];
    }
  };

  const onMenuScrollToBottom = (e) => {
    const { totalRecords, pageNo, pageSize } = skus?.pagination || {};

    if (pageNo * pageSize >= totalRecords) return;

    setSkusPagination({
      ...skusPagination,
      pageNo: skusPagination.pageNo + 1,
    });
  };

  const onChange = (selectedSkus) => {
    if (selectedSkus?.length > 0) {
      setSelectedSkusId(selectedSkus.map((item) => item.skuId));
    }
  };

  const handleSubmit = () => {
    dispatch(
      createVouchers({
        offerName,
        voucherPrefix,
        type,
        discount: parseFloat(discount),
        lifeSpan: parseInt(lifeSpan, 10),
        startDate: moment(startDate).utc().format("YYYY-MM-DD HH:mm:ss"),
        endDate: moment(endDate).utc().format("YYYY-MM-DD HH:mm:ss"),
        maxAmount: parseInt(maxAmount, 10),
        description,
        applicableSkus: selectAllSkus ? null : selectedSkusId,
        selectAllSkus: selectAllSkus ? 1 : 0,
        generateMultiple: parseInt(generateMultiple, 10),
      })
    );
    history.push("/vouchers");
  };

  return (
    <div className="row justify-content-center">
      <div className="col-10">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-10">
                {/* Offer Name field */}

                <div className="row mb-3">
                  <label htmlFor="offer-name" className="col-2 col-form-label">
                    Offer Name:
                  </label>
                  <div className="col-9">
                    <input
                      type="text"
                      className="form-control"
                      value={offerName}
                      onChange={(e) => {
                        setOfferName(e.target.value);
                      }}
                      onBlur={(e) => {
                        if (e.target.value === "") {
                          setValidateOfferName(false);
                        } else {
                          setValidateOfferName(true);
                        }
                      }}
                      id="offer-name"
                      placeholder="Offer Name"
                      autoComplete="off"
                    />
                    {validateOfferName === false ? (
                      <div className="text-danger">
                        Please give a offer name.
                      </div>
                    ) : null}
                  </div>
                </div>

                {/* Prefix field */}

                <div className="row mb-3">
                  <label htmlFor="prefix" className="col-2 col-form-label">
                    Voucer Prefix:
                  </label>
                  <div className="col-9">
                    <input
                      type="text"
                      className="form-control"
                      value={voucherPrefix}
                      onChange={(e) => {
                        setVoucherPrefix(e.target.value);
                      }}
                      onBlur={(e) => {
                        if (e.target.value === "") {
                          setValidateVoucherPrefix(false);
                        } else {
                          setValidateVoucherPrefix(true);
                        }
                      }}
                      id="prefix"
                      placeholder="Prefix"
                      autoComplete="off"
                    />
                    {validateVoucherPrefix === false ? (
                      <div className="text-danger">
                        Please set voucher prefix
                      </div>
                    ) : null}
                  </div>
                </div>

                {/* Type Select */}
                <div className="row mb-3">
                  <label htmlFor="type" className="col-2 col-form-label">
                    Type:
                  </label>
                  <div className="col-9">
                    <select
                      className="form-select"
                      id="type"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                      placeholder="Select Voucher Type"
                      onBlur={(e) => {
                        if (e.target.value === "Select Event Type") {
                          setValidateType(false);
                        } else {
                          setValidateType(true);
                        }
                      }}
                    >
                      <option value="" disabled selected>
                        Select Event Type
                      </option>
                      <option>Discount</option>
                      <option>Flat</option>
                    </select>
                    {validateType === false ? (
                      <div className="text-danger">
                        Please select event type
                      </div>
                    ) : null}
                  </div>
                </div>

                {/* Discount Amount / Percentage Field */}
                <div className="row mb-3">
                  <label htmlFor="discount" className="col-2 col-form-label">
                    {type === "Flat" ? "Amount: " : "Percentage %: "}
                  </label>
                  <div className="col-9">
                    <input
                      type="text"
                      className="form-control"
                      value={discount}
                      onChange={(e) => {
                        setDiscount(e.target.value);
                      }}
                      onBlur={(e) => {
                        if (e.target.value === "") {
                          setValidateDiscount(false);
                        } else {
                          setValidateDiscount(true);
                        }
                      }}
                      id="discount"
                      placeholder="Discount"
                      autoComplete="off"
                    />
                    {validateDiscount === false ? (
                      <div className="text-danger">
                        Please set voucher prefix
                      </div>
                    ) : null}
                  </div>
                </div>

                {/* Life Span field */}
                <div className="row mb-3">
                  <label htmlFor="prefix" className="col-2 col-form-label">
                    Life Span:
                  </label>
                  <div className="col-9">
                    <input
                      type="text"
                      className="form-control"
                      value={lifeSpan}
                      onChange={(e) => {
                        setLifeSpan(e.target.value);
                      }}
                      onBlur={(e) => {
                        if (e.target.value === "") {
                          setValidateLifeSpan(false);
                        } else {
                          setValidateLifeSpan(true);
                        }
                      }}
                      id="lifeSpan"
                      placeholder="Number of times voucher can be used"
                      autoComplete="off"
                    />
                    {validateLifeSpan === false ? (
                      <div className="text-danger">
                        Please set voucher life span
                      </div>
                    ) : null}
                  </div>
                </div>

                {/* Start Date Field */}
                <div className="row mb-3">
                  <label htmlFor="prefix" className="col-2 col-form-label">
                    Start Date:
                  </label>
                  <div className="col-9">
                    <input
                      type="datetime-local"
                      className="form-control"
                      value={startDate}
                      onChange={(e) => {
                        setStartDate(e.target.value);
                      }}
                      onBlur={(e) => {
                        if (e.target.value === "") {
                          setValidateStartDate(false);
                        } else {
                          setValidateStartDate(true);
                        }
                      }}
                      id="startDate"
                      placeholder="Start Date"
                      autoComplete="off"
                    />
                    {validateStartDate === false ? (
                      <div className="text-danger">
                        Please set voucher's Start Date
                      </div>
                    ) : null}
                  </div>
                </div>

                {/* End Date Field  */}
                <div className="row mb-3">
                  <label htmlFor="prefix" className="col-2 col-form-label">
                    End Date:
                  </label>
                  <div className="col-9">
                    <input
                      type="datetime-local"
                      className="form-control"
                      value={endDate}
                      onChange={(e) => {
                        setEndDate(e.target.value);
                      }}
                      onBlur={(e) => {
                        if (e.target.value === "") {
                          setValidateEndDate(false);
                        } else {
                          setValidateEndDate(true);
                        }
                      }}
                      id="endDate"
                      placeholder="End Date"
                      autoComplete="off"
                    />
                    {validateEndDate === false ? (
                      <div className="text-danger">
                        Please set voucher's End Date
                      </div>
                    ) : null}
                  </div>
                </div>

                {/* Max Amount Field */}
                <div className="row mb-3">
                  <label htmlFor="prefix" className="col-2 col-form-label">
                    Max Amount:
                  </label>
                  <div className="col-9">
                    <input
                      type="text"
                      className="form-control"
                      value={maxAmount}
                      onChange={(e) => {
                        setMaxAmount(e.target.value);
                      }}
                      id="maxAmount"
                      placeholder="Set Max amount for Voucher"
                      autoComplete="off"
                    />
                  </div>
                </div>

                {/* Description Field */}
                <div className="row mb-3">
                  <label htmlFor="prefix" className="col-2 col-form-label">
                    Description:
                  </label>
                  <div className="col-9">
                    <input
                      type="text"
                      className="form-control"
                      value={description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                      onBlur={(e) => {
                        if (e.target.value === "") {
                          setValidateDescription(false);
                        } else {
                          setValidateDescription(true);
                        }
                      }}
                      id="description"
                      placeholder="Description for voucher"
                      autoComplete="off"
                    />
                    {validateDescription === false ? (
                      <div className="text-danger">
                        Please set voucher description
                      </div>
                    ) : null}
                  </div>
                </div>

                {/* Applicable Plans Multi select */}
                <div className="row mb-3">
                  <label htmlFor="prefix" className="col-2 col-form-label">
                    Applicable SKUs:
                  </label>
                  <div className="col-9">
                    <AsyncSelect
                      isMulti={true}
                      isDisabled={selectAllSkus}
                      defaultOptions={skusList}
                      onChange={onChange}
                      isLoading={isLoading}
                      loadOptions={onSearchGetSkus}
                      inputValue={skuSearchField}
                      onInputChange={setSkuSearchField}
                      placeholder={"Select SKUs"}
                      noOptionsMessage={() => "Not SKU Found"}
                      getOptionLabel={(option) =>
                        `${option.skuName} - ${option.skuId}`
                      }
                      getOptionValue={(option) => option.skuId}
                      onMenuScrollToBottom={onMenuScrollToBottom}
                    />
                    <div className="my-1">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        checked={selectAllSkus}
                        onChange={(e) => {
                          setSelectAllSkus(!selectAllSkus);
                        }}
                      />
                      <label class="form-check-label px-2">Select All</label>
                    </div>
                  </div>
                </div>

                {/* Generate Multiple Field  */}
                <div className="row mb-1">
                  <label htmlFor="prefix" className="col-2 col-form-label">
                    Generate Multiple:
                  </label>
                  <div className="col-9">
                    <input
                      type="text"
                      className="form-control"
                      value={generateMultiple}
                      onChange={(e) => {
                        setGenerateMultiple(e.target.value);
                      }}
                      onBlur={(e) => {
                        if (e.target.value === "") {
                          setValidateGenerateMultiple(false);
                        } else {
                          setValidateGenerateMultiple(true);
                        }
                      }}
                      id="generateMultiple"
                      placeholder="Specify amount of vouchers requried"
                      autoComplete="off"
                    />
                    {validateGenerateMultiple === false ? (
                      <div className="text-danger">
                        Please set voucher prefix
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="justify-content-center d-flex"
                  style={{ marginTop: "1.75rem" }}
                >
                  <button
                    type="submit"
                    className="btn btn-success"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Create Vouchers
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VouchersForm;
