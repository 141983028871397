import {
  GET_EXPERT_AVAILABILITY,
  RESET_EXPERT_AVAILABILITY,
  SET_EXPERT_AVAILABILITY,
  SET_EXPERT_AVAILABILITY_ERROR,
} from "../../middlewares/types";

const initialState = {
  expertAvailability: {
    isLoading: false,
    payload: null,
    error: null,
  },
};

const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EXPERT_AVAILABILITY:
      return {
        ...state,
        expertAvailability: {
          isLoading: true,
          payload: null,
          error: null,
        },
      };
    case SET_EXPERT_AVAILABILITY:
      return {
        ...state,
        expertAvailability: {
          isLoading: false,
          payload: action.payload,
          error: null,
        },
      };
    case SET_EXPERT_AVAILABILITY_ERROR:
      return {
        ...state,
        expertAvailability: {
          isLoading: false,
          payload: null,
          error: action.payload,
        },
      };
    case RESET_EXPERT_AVAILABILITY:
      return {
        ...state,
        expertAvailability: initialState.expertAvailability,
      };
    default:
      return state;
  }
};

export default eventsReducer;
