import { createStore, applyMiddleware, compose, combineReducers } from "redux";

import { apiMiddleware } from "../middlewares/core/api";
import { acqChannelMiddleware } from "../middlewares/feature/acqChannel";
import { loginMiddleware } from "../middlewares/feature/login";
import { primaryConcenMiddleware } from "../middlewares/feature/primaryConcern";
import { userMiddleware } from "../middlewares/feature/user";
import { usersMiddleware } from "../middlewares/feature/users";
import { userPrimaryConcenMiddleware } from "../middlewares/feature/userPrimaryConcern";
import { symptomMiddleware } from "../middlewares/feature/symptom";
import { userSymptomMiddleware } from "../middlewares/feature/userSymptom";
import { offeringMiddleware } from "../middlewares/feature/offering";
import { providerMiddleware } from "../middlewares/feature/provider";
import { eventMiddleware } from "../middlewares/feature/event";
import { planMiddleware } from "../middlewares/feature/plan";
import { insightsMiddleware } from "../middlewares/feature/insights";
import { exotelMiddleware } from "../middlewares/feature/exotel";
import { razorpayMiddleware } from "../middlewares/feature/razorpay";
import { userPlanMiddleware } from "../middlewares/feature/userPlan";
import { promoCodesMiddleware } from "../middlewares/feature/promoCodes";
import { cronMiddleware } from "../middlewares/feature/crons";
import { photoUploadMiddleware } from "../middlewares/core/photoUpload";
import { fileUploadMiddleware } from "../middlewares/core/fileUpload";
import { navbarStatsMiddleware } from "../middlewares/feature/navbarStats";
import { labTestsMiddleware } from "../middlewares/feature/labTests";
import { tasksMiddleware } from "../middlewares/feature/tasks";
import { challengesMiddleware } from "../middlewares/feature/challenges";
import { webinarMiddleware } from "../middlewares/feature/webinar";
import { mealsMiddleware } from "../middlewares/feature/meals";
import { paymentsMiddleware } from "../middlewares/feature/payments";
import { contentMiddleware } from "../middlewares/feature/content";
import { topicsContentMapMiddleware } from "../middlewares/feature/topicsContentMap";
import { paymentsPlanMiddleware } from "../middlewares/feature/paymentsPlan";
import { expertMiddleware } from "../middlewares/feature/expert";
import { SKUMiddleware } from "../middlewares/feature/SKU";
import { commentMiddleware } from "../middlewares/feature/comments";
import { ordersMiddleware } from "../middlewares/feature/orders";
import { doctorFacingTechMiddleware } from "../middlewares/feature/doctorFacingTech";

import { authReducer } from "../reducers/login/";
import { acqChannelReducer } from "../reducers/acqChannel";
import { primaryConcernReducer } from "../reducers/primaryConcern";
import { usersReducer } from "../reducers/users";
import { userReducer } from "../reducers/user";
import { userPrimaryConcernReducer } from "../reducers/userPrimaryConcern";
import { symptomReducer } from "../reducers/symptom";
import { userSymptomReducer } from "../reducers/userSymptom";
import { offeringReducer } from "../reducers/offering";
import { providerReducer } from "../reducers/provider";
import { eventReducer } from "../reducers/Event";
import { planReducer } from "../reducers/plan";
import { insightsReducer } from "../reducers/insights";
import { exotelReducer } from "../reducers/exotel";
import { razorpayReducer } from "../reducers/razorpay";
import { couponsReducer } from "../reducers/promoCodes/coupons";
import { vouchersReducer } from "../reducers/promoCodes/vouchers";
import { cronReducer } from "../reducers/crons";
import { presignedUrlsReducer } from "../reducers/presignedUrls";
import { navbarStatsReducer } from "../reducers/navbarStats";
import { labTestsReducer } from "../reducers/labTests";
import { tasksReducer } from "../reducers/tasks";
import { challengesReducer } from "../reducers/challenges";
import { webinarReducer } from "../reducers/webinar";
import { mealsReducer } from "../reducers/meals";
import { contentReducer } from "../reducers/content";
import { toicpsContentMapReducer } from "../reducers/topicsContentMap";
import { paymentsReducer } from "../reducers/payments";
import { paymentsPlanReducer } from "../reducers/paymentsPlan";
import { expertReducer } from "../reducers/expert";
import { commentsReducer } from "../reducers/comments";
import { SKUReducer } from "../reducers/SKU";
import { ordersReducer } from "../reducers/orders";
import { doctorFacingTechReducer } from "../reducers/doctorFacingTech";
import {doctorFacingReducer} from "../reducers/v1/doctorFacingTech"
import skusReducer from "../reducers/v1/skusReducer";
import { skusFeature } from "../middlewares/feature/v1/skusFeature";
import eventsReducer from "../reducers/v1/eventsReducer";
import { eventsFeature } from "../middlewares/feature/v1/eventsFeature";
import { dftFeature } from "../middlewares/feature/v1/dftFeature";

const coreMiddleware = [apiMiddleware];
const featureMiddleware = [
  loginMiddleware,
  acqChannelMiddleware,
  primaryConcenMiddleware,
  userMiddleware,
  usersMiddleware,
  userPrimaryConcenMiddleware,
  symptomMiddleware,
  userSymptomMiddleware,
  offeringMiddleware,
  providerMiddleware,
  eventMiddleware,
  planMiddleware,
  insightsMiddleware,
  exotelMiddleware,
  razorpayMiddleware,
  userPlanMiddleware,
  promoCodesMiddleware,
  cronMiddleware,
  photoUploadMiddleware,
  navbarStatsMiddleware,
  labTestsMiddleware,
  tasksMiddleware,
  challengesMiddleware,
  webinarMiddleware,
  mealsMiddleware,
  contentMiddleware,
  topicsContentMapMiddleware,
  paymentsMiddleware,
  paymentsPlanMiddleware,
  fileUploadMiddleware,
  expertMiddleware,
  SKUMiddleware,
  commentMiddleware,
  ordersMiddleware,
  doctorFacingTechMiddleware,
  skusFeature,
  eventsFeature,
  dftFeature,
];

let enhance;
if (process.env.NODE_ENV === "development") {
  enhance = compose(
    applyMiddleware(...featureMiddleware, ...coreMiddleware)
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
} else {
  enhance = compose(applyMiddleware(...featureMiddleware, ...coreMiddleware));
}

const rootReducer = combineReducers({
  authReducer,
  acqChannelReducer,
  primaryConcernReducer,
  usersReducer,
  userReducer,
  userPrimaryConcernReducer,
  symptomReducer,
  userSymptomReducer,
  offeringReducer,
  providerReducer,
  eventReducer,
  planReducer,
  insightsReducer,
  exotelReducer,
  razorpayReducer,
  couponsReducer,
  vouchersReducer,
  cronReducer,
  presignedUrlsReducer,
  navbarStatsReducer,
  labTestsReducer,
  tasksReducer,
  challengesReducer,
  webinarReducer,
  mealsReducer,
  contentReducer,
  toicpsContentMapReducer,
  paymentsReducer,
  paymentsPlanReducer,
  expertReducer,
  SKUReducer,
  commentsReducer,
  ordersReducer,
  doctorFacingTechReducer,
  skus: skusReducer,
  events: eventsReducer,
  doctorFacingTech: doctorFacingReducer,
});

const store = createStore(rootReducer, enhance);

export default store;
