import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from '../navbar';
import { getAllSKUs, updateSkuStatus, deleteSku } from '../../actions/SKU';
import { SKUSelector } from '../../selector/SKU';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import Toggle from "react-toggle";
import CommonTableContainer from "../common/PaginationTable";

const SKU = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState("");
    const [debouncedSearch, setDebouncedSearch] = useState(search);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedSearch(search);
        }, 500);
        return () => clearTimeout(timer);
    }, [search]);

    useEffect(() => {
        dispatch(getAllSKUs({ page, pageSize, search: debouncedSearch }));
    }, [dispatch, page, pageSize, debouncedSearch]);

    const skus = useSelector(state => SKUSelector(state));

    const data = skus?.skuData?.length > 0 ? skus.skuData?.map((sku) => ({
        id: sku.skuId,
        skuName: sku.skuName,
        skuMapId: sku.skuMapId,
        skuCategory: sku.skuCategory,
        skuSubCategory: sku.skuSubCategory,
        skuUnit: `${sku.skuUnit} x ${sku.skuUnitType}`,
        status: sku.status,
        action: sku.skuId
    })):[];

    const columns = [
        { headerName: "SKU ID", field: "id", width: 100 },
        { headerName: "SKU Name", field: "skuName", flex: 1 },
        { headerName: "SKU Map ID", field: "skuMapId", width: 100 },
        { headerName: "SKU Category", field: "skuCategory", width: 120 },
        { headerName: "SKU Sub Category", field: "skuSubCategory", width: 150 },
        { headerName: "SKU Unit", field: "skuUnit", flex: 0.5 },
        {
            headerName: "Status",
            field: "status",
            renderCell: (params) => (
                <Toggle
                    defaultChecked={params.value === 'active'}
                    onChange={() => dispatch(updateSkuStatus({ skuId: params.row.id, status: params.value === 'active' ? 'inactive' : 'active' }))}
                />
            ),
            width: 100,
        },
        {
            headerName: "Action",
            field: "action",
            renderCell: (params) => (
                <div className="d-flex">
                    <i className="mdi mdi-pencil text-warning font-20 mx-2" onClick={() => history.push(`/updateSKU/${params.value}`)}></i>
                    <i className="mdi mdi-delete text-danger font-20" onClick={() => handleDelete(params.value)}></i>
                </div>
            ),
            width: 100,
        },
    ];

    const handleDelete = (skuId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteSku({ skuId }));
            }
        });
    };

    return (
        <Navbar pageTitle={"SKUs Listing"}>
            <div className="col-12 d-flex justify-content-between px-3 mb-2">
                <input
                    type="text"
                    placeholder="Search SKU Name..."
                    className="gridjs-input gridjs-search-input"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>
            <CommonTableContainer
                rows={data}
                columns={columns}
                pageSize={pageSize}
                currentPage={page}
                totalRecords={skus.totalRecords || 0}
                onPageChange={setPage}
                rowKey="id"
            />
        </Navbar>
    );
};

export default SKU;
