import Multiselect from 'multiselect-react-dropdown'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createLabTestSKU,deleteLabTestSKU, getAllLabTestSKUs, getLabTestsConfig, getLabTestThumbnailPresignedUrl, updateLabTestSKU } from '../../actions/labTests'
import { labTestCategorySelector, labTestPartnerSelector, labTestSKUsSelector } from '../../selector/labTests'
import { labTestThumbnailPresignedUrlSelector } from '../../selector/presignedUrlSelector'
import Navbar from '../navbar'
import axios from 'axios'
import Alert from "sweetalert2";
import { useHistory } from 'react-router-dom'
import { getAllSKUs, setAllSKUs } from '../../actions/SKU'
import { SKUSelector } from '../../selector/SKU'
import RichTextEditor from './RichTextEditor'
import Swal from 'sweetalert2'

const TestAvailableFields = (
    {
        availableTests,
        setAvailableTests,
        labPartnerId,
        label,
        selectedAvailableTests,
        setSelectedAvailableTests
    }) => {
    const dispatch=useDispatch()
    useEffect(()=>{
        dispatch(getAllLabTestSKUs()) 
    },[dispatch])
    const [fieldText,setFieldText] = useState('')
    const [file, setFile] = useState();
    const [val,setVal] = useState('')
    
    const [validateFieldText,setValidateFieldText] = useState(true)
    const handleInput = (e) => {
        const filteredSelected = selectedAvailableTests.filter(item=>Object.keys(item)[0]!==labPartnerId)
        setSelectedAvailableTests([...filteredSelected,{[labPartnerId]: fieldText}])        
    }

    useEffect(()=>{
        if(availableTests.length>0){
            const partnerDetails = availableTests.find(partner=>partner[labPartnerId])
            setFieldText(partnerDetails?partnerDetails[labPartnerId]:'')            
        }
    },[labPartnerId,availableTests])

    // CSV IMPORTS
    const handleOnChange = (e) => {
        setFile(e.target.files[0]);
    };
    const fileReader = new FileReader();
    const handleOnSubmit = (e) => {
        e.preventDefault();
        if (file) {
            fileReader.onload = function (event) {
                const csvOutput = event.target.result;
                let data = csvOutput.split("\r\n");
                    for (let i in data) {
                        data[i] = data[i].split(",");
                    }
                const testMap = new Map()
                data.forEach((item)=>{
                    if(testMap.has(item[0])){
                        testMap.set(item[0],[...testMap.get(item[0]),item[1]])
                    }else{
                        testMap.set(item[0],[item[1]])
                    }
                })
                setVal(JSON.stringify(Object.fromEntries(testMap)))
            };
        }
        fileReader.readAsText(file);
    };
    useEffect(()=>{
        setFieldText(val)
        const filteredSelected = selectedAvailableTests.filter(item=>Object.keys(item)[0]!==labPartnerId)
        setSelectedAvailableTests([...filteredSelected,{[labPartnerId]: val}]) 
    },[val])

    return (
            <div className="row mt-1">
                <div className="col-2">
                    <label
                        htmlFor="lab-test-available"
                        className="col-12 col-form-label"
                    >
                        {label}:
                    </label>
                </div>
                <div className="col-10">
                    <textarea
                        type="text-area"
                        className="form-control"
                        style={{height:'120px'}}
                        value={fieldText}
                        onChange={(e) => {
                            setFieldText(e.target.value)
                            handleInput(e);
                        }}
                        onBlur={(e)=>{
                            if(e.target.value === ''){
                                setValidateFieldText(false)
                            }else{
                                handleInput(e);
                                setValidateFieldText(true)
                            }
                        }}
                        id="lab-test-description"
                        placeholder="Lab Test Description"
                        autoComplete="off"
                    />
                    <form className='d-flex justify-content-end'>
                        <input 
                            type="file" 
                            accept=".csv"
                            onChange={handleOnChange}
                            style={{display:'block'}}
                            className="mt-2"
                        />

                        <button
                            className="btn btn-outline-success btn-rounded mt-2"
                            style={{padding:'1px' ,width:'100px'}}
                            onClick={(e) => {
                                handleOnSubmit(e);
                            }}
                        >
                            IMPORT CSV
                        </button>
                    </form>
                    {validateFieldText === false ?(
                        <div className="text-danger">
                            Please give tests available
                        </div>
                    ):null}
                </div>  
            </div>
    )
}

const LabTestThumbanailUpload = ({thumbnailUrl,setThumbnailUrl, src}) => {
    const dispatch = useDispatch()
    const [labTestThumbnail,setLabTestThumbnail] = useState('')
    
    const onFileChange = (e, orderId) => {
        setLabTestThumbnail(e.target.files[0])
        dispatch(getLabTestThumbnailPresignedUrl(e.target.files[0].type))
    }
    
    const uploadConfigs = useSelector(state=>labTestThumbnailPresignedUrlSelector(state))
    
    const uploadFile = async() => {
        console.log(uploadConfigs.url)
        console.log(labTestThumbnail)
        await axios.put(
            uploadConfigs.url,
            labTestThumbnail,
            {
                headers: {
                    'Content-Type': labTestThumbnail.type,
                }
            }).then((val)=>{
            setThumbnailUrl(`https://image-store-admin.s3.amazonaws.com/${uploadConfigs.key}`)
            Alert.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Thumbnail Uploaded',
                showConfirmButton: false,
                timer: 1500
              })
        }).catch((err)=>{
            console.log('err = ',err)
           Alert.fire({
               position: 'top-end',
               icon: 'error',
               title: 'Lab Report Failed',
               showConfirmButton: false,
               timer: 1500
             })
        })
    }
    return(
        <div className="row mb-3">
            <label
                htmlFor="lab-test-actual-price"
                className="col-3 col-form-label"
            >
                Lab Test Thumbnail:
            </label>
            <div className="col-9">
                <div>
                    <h4>Upload Lab Test Thumbnail</h4>
                    <img 
                        src={labTestThumbnail? URL.createObjectURL(labTestThumbnail) : src} 
                        alt="profile"
                        style={{height:'50px',width:'50px'}}    
                        />
                    
                    <div>
                    
                        {labTestThumbnail===''?
                        <>
                            <h5>Choose lab test thumbnail <span className='text-primary'>[ Dimension: 192 x 192 ]</span></h5>
                            <input 
                                type="file" 
                                accept="image/*"
                                onChange={(event)=>{onFileChange(event)}}
                                style={{display:'block'}}
                                />
                        </>
                        
                        :<button 
                            className="btn btn-info btn-rounded mt-3"
                            onClick={uploadFile}
                            style={{padding:'2px' ,width:'100px'}}
                        >   
                            Upload
                        </button>
                        }
                    </div>
                </div>      
                                
            </div>
        </div>
    )

}

const CreateLabTest = (props) => {

    const labTestId = props.match.params.labTestId

    const dispatch = useDispatch()
    const history = useHistory()

    const [labTestName,setLabTestName] = useState('')
    const [validateLabTestName,setValidateLabTestName] = useState(true)

    const [actualPrice,setActualPrice] = useState('')
    const [validateActualPrice,setValidateActualPrice] = useState(true)

    const [strikeOffPrice, setStrikeOffPrice] = useState('')
    const [validateStrikeOffPrice,setValidateStrikeOffPrice] = useState(true)

    const [labTestRequirements,setLabTestRequirements] = useState('')
    const [validateLabTestRequirements,setValidateLabTestRequirements] = useState(true)

    const [labTestDescription,setLabTestDescription] = useState('')
    const [validateLabTestDescription,setValidateLabTestDescription] = useState(true)

    const [labTestSearchTerms,setLabTestSearchTerms] = useState('')
    const [validateLabTestSearhTerms,setValidateLabTestSearchTerms] = useState(true)

    const [labTestSummaryPoints,setLabTestSummaryPoints] = useState('')
    const [validateLabTestSummaryPoints,setValidateLabTestSummaryPoints] = useState(true)

    const [labTestWATemplateId,setLabTestWATemplateId] = useState('')
    const [validateLabTestWATemplateId,setValidateLabTestWATemplateId] = useState(true)
    
    const [labTestSpreadsheetName,setLabTestSpreadsheetName] = useState('')
    const [validateSpreadsheetName,setValidateSpreadsheetName] = useState(true)

    const [labTestType,setLabTestType] = useState('')
    const [validateLabTestType,setValidateLabTestType] = useState(true)


    const [labTestCategory,setLabTestCategory] = useState([])
    const [selectedLabTestCategory,setSelectedLabTestCategory] = useState([])
    const [validateLabTestCategory,setValidateLabTestCategory] = useState(true)

    const [labPartner,setLabPartner] = useState([])
    const [selectedLabPartner,setSelectedLabPartner] = useState([])
    const [validateLabPartner,setValidateLabPartner] = useState(true)

    const [availableTests,setAvailableTests] = useState([])
    const [selectedAvailableTests,setSelectedAvailableTests] = useState([])

    
    const [allSkus,setAllSkus] = useState([])
    const [recommendedSkus,setRecommendedSkus] = useState([])
    
    const [allBadges,setAllBadges] = useState('')
    const [validateAllBadges,setValidateAllBadges] = useState(true)
    
    const [faq,setFaq] = useState('')
    const [validateFaq,setValidateFaq] = useState(true)
    
    const [partOfPanel,setPartOfPanel] = useState([])
    
    const [thumbnailUrl,setThumbnailUrl] = useState('')
    let src = '/assets/images/users/avatar.png'
    
    useEffect(()=>{
        dispatch(getLabTestsConfig())
        dispatch(getAllSKUs({}))
    },[dispatch])
    
    useEffect(()=>{
        dispatch(getAllLabTestSKUs())
    },[labTestId,dispatch])

    const allLabTests = useSelector(state=> labTestSKUsSelector(state))
    const currentLabTest = allLabTests.filter((test)=>test.sku_id===labTestId)[0]
    src = currentLabTest?currentLabTest.thumbnailUrl:'/assets/images/users/avatar.png'

    useEffect(()=>{
        if(labTestId && currentLabTest){
            setLabTestName(currentLabTest.name)
            setActualPrice(currentLabTest.actualPrice)
            setStrikeOffPrice(currentLabTest.strikeOffPrice)
            setLabTestRequirements(currentLabTest.requirements)
            setLabTestDescription(currentLabTest.description)
            setLabTestSearchTerms(currentLabTest.searchTerms)
            setLabTestSummaryPoints(currentLabTest.summaryPoints)
            // setLabTestWATemplateId(currentLabTest.waTemplateId)
            setLabTestSpreadsheetName(currentLabTest.spreadsheetName)
            setLabTestType(currentLabTest.subCategory)
            setThumbnailUrl(currentLabTest.thumbnailUrl)
            setFaq(currentLabTest.faq || '')
            setPartOfPanel(currentLabTest.selectedPanels)
            setAllBadges(currentLabTest.badges)
            setSelectedLabTestCategory(currentLabTest.selectedLabTestCategory)
            setRecommendedSkus(currentLabTest.selectedRecommendedSkus)
            setSelectedLabPartner(currentLabTest.selectedLabPartner)
            setAvailableTests(currentLabTest.availableTests)
            setSelectedAvailableTests(currentLabTest.availableTests)

        } else{
            setLabTestName('')
            setActualPrice('')
            setStrikeOffPrice('')
            setLabTestRequirements('')
            setLabTestDescription('')
            setLabTestSearchTerms('')
            setLabTestSummaryPoints('')
            setLabTestWATemplateId('')
            setLabTestSpreadsheetName('')
            setLabTestType('')
            setThumbnailUrl('')
            setFaq('')
            setPartOfPanel([])
            setAllBadges('')
            setSelectedLabTestCategory([])
            setRecommendedSkus([])
            setSelectedLabPartner([])
            setAvailableTests([])
            setPartOfPanel([])
        }
    },[dispatch,labTestId,currentLabTest])


    const labTestCategoryValues = useSelector(state => labTestCategorySelector(state))
    const labTestPartnerValues = useSelector(state => labTestPartnerSelector(state))
    const {skuData :skus} = useSelector((state)=> SKUSelector(state))
    
    useEffect(()=>{
        setLabTestCategory(labTestCategoryValues)
        setLabPartner(labTestPartnerValues)
    },[labTestCategoryValues,labTestPartnerValues])

    useEffect(()=>{
        const skuOptionsArray = skus?skus.map((item)=>{return({name:`${item.skuName} - ${item.skuId}`,id:item.skuId})}):[]
        setAllSkus(skuOptionsArray)
    },[skus])


    const handleSubmit = () => {
        if(labTestId){
            dispatch(updateLabTestSKU(
                {
                    labTestId,
                    skuId:currentLabTest.skuId,
                    labTestName,
                    actualPrice,
                    strikeOffPrice,
                    labTestRequirements,
                    labTestDescription,
                    labTestWATemplateId,
                    labTestSpreadsheetName,
                    labTestType,
                    selectedLabTestCategory,
                    selectedLabPartner,
                    thumbnailUrl,
                    availableTests:selectedAvailableTests,
                    recommendedSkus,
                    allBadges,
                    faq,
                    partOfPanel,
                    labTestSearchTerms,
                    labTestSummaryPoints
                ,}
            ))
        }else{
            dispatch(createLabTestSKU(
                {
                    labTestName,
                    actualPrice,
                    strikeOffPrice,
                    labTestRequirements,
                    labTestDescription,
                    labTestWATemplateId,
                    labTestSpreadsheetName,
                    labTestType,
                    selectedLabTestCategory,
                    selectedLabPartner,
                    thumbnailUrl,
                    availableTests:selectedAvailableTests,
                    recommendedSkus,
                    allBadges,
                    faq,
                    partOfPanel,
                    labTestSearchTerms,
                    labTestSummaryPoints,
                }
            ))
        }
        
        history.push('/labTestsListing')
    }

    const handleDelete = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch(deleteLabTestSKU({labTestSKUId: labTestId}))
                    history.push('/labTestsListing')
                }
        })
    }
  return (
    <Navbar pageTitle={"Create New Lab Test"}>
         <div className="row justify-content-center">
        <div className="col-12">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-1"></div>
                        <div className="col-lg-10">

                            {/* Lab Test Name */}
                            <div className="row mb-3">
                                <label
                                    htmlFor="lab-test-name"
                                    className="col-3 col-form-label"
                                >
                                    Lab Test Name: 
                                </label>
                                <div className="col-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={labTestName}
                                        onChange={(e) => {
                                            setLabTestName(e.target.value);
                                        }}
                                        onBlur={(e)=>{
                                            if(e.target.value === ''){
                                                setValidateLabTestName(false)
                                            }else{
                                                setValidateLabTestName(true)
                                            }
                                        }}
                                        id="offer-name"
                                        placeholder="Lab Test Name"
                                        autoComplete="off"
                                    />
                                    {validateLabTestName === false ?(
                                        <div className="text-danger">
                                            Please give a Lab Test Name.
                                        </div>
                                    ):null}
                                </div>
                            </div>

                            {/* Lab Test Actual Price */}
                            <div className="row mb-3">
                                <label
                                    htmlFor="lab-test-actual-price"
                                    className="col-3 col-form-label"
                                >
                                    Lab Test Actual Price:
                                </label>
                                <div className="col-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={actualPrice}
                                        onChange={(e) => {
                                            setActualPrice(e.target.value);
                                        }}
                                        onBlur={(e)=>{
                                            if(e.target.value === ''){
                                                setValidateActualPrice(false)
                                            }else{
                                                setValidateActualPrice(true)
                                            }
                                        }}
                                        id="lab-test-actual-price"
                                        placeholder="The user will pay this price for the lab test"
                                        autoComplete="off"
                                    />
                                    {validateActualPrice === false ?(
                                        <div className="text-danger">
                                            Please give a Lab Test Actual Price.
                                        </div>
                                    ):null}
                                                 
                                </div>
                            </div>
                            
                            {/* Lab Test Strike Off Price */}
                            <div className="row mb-3">
                                <label
                                    htmlFor="lab-test-strike-off-price"
                                    className="col-3 col-form-label"
                                >
                                    Lab Test Strike Off Price:
                                </label>
                                <div className="col-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={strikeOffPrice}
                                        onChange={(e) => {
                                            setStrikeOffPrice(e.target.value);
                                        }}
                                        onBlur={(e)=>{
                                            if(e.target.value === ''){
                                                setValidateStrikeOffPrice(false)
                                            }else{
                                                setValidateStrikeOffPrice(true)
                                            }
                                        }}
                                        id="lab-test-strike-off-price"
                                        placeholder="Fake price that is striked off to show discount"
                                        autoComplete="off"
                                    />
                                    {validateStrikeOffPrice === false ?(
                                        <div className="text-danger">
                                            Please give Lab Test Strike Off Price.
                                        </div>
                                    ):null}
                                                 
                                </div>
                            </div>

                            {/* Lab Test Requirements */}
                            <div className="row mb-3">
                                <label
                                    htmlFor="lab-test-requirements"
                                    className="col-3 col-form-label"
                                >
                                    Lab Test Requirements:
                                </label>
                                <div className="col-9">
                                    <textarea
                                        type="text-area"
                                        className="form-control"
                                        style={{height:'120px'}}
                                        value={labTestRequirements}
                                        onChange={(e) => {
                                            setLabTestRequirements(e.target.value);
                                        }}
                                        onBlur={(e)=>{
                                            if(e.target.value === ''){
                                                setValidateLabTestRequirements(false)
                                            }else{
                                                setValidateLabTestRequirements(true)
                                            }
                                        }}
                                        id="lab-test-requirements"
                                        placeholder="Lab Test Requirements"
                                        autoComplete="off"
                                    />
                                    {validateLabTestRequirements === false ?(
                                        <div className="text-danger">
                                            Please give Lab Test Requirements.
                                        </div>
                                    ):null}
                                                 
                                </div>
                            </div>

                            {/* Lab Test Description */}
                            <div className="row mb-3">
                                <label
                                    htmlFor="lab-test-description"
                                    className="col-3 col-form-label"
                                >
                                    Lab Test Description:
                                </label>
                                <div className="col-9">
                                    <textarea
                                        type="text-area"
                                        className="form-control"
                                        style={{height:'120px'}}
                                        value={labTestDescription}
                                        onChange={(e) => {
                                            setLabTestDescription(e.target.value);
                                        }}
                                        onBlur={(e)=>{
                                            if(e.target.value === ''){
                                                setValidateLabTestDescription(false)
                                            }else{
                                                setValidateLabTestDescription(true)
                                            }
                                        }}
                                        id="lab-test-description"
                                        placeholder="Lab Test Description"
                                        autoComplete="off"
                                    />
                                    {validateLabTestDescription === false ?(
                                        <div className="text-danger">
                                            Please give Lab Test Description.
                                        </div>
                                    ):null}
                                                 
                                </div>
                            </div>

                            {/* Lab Test Search Terms */}
                            <div className="row mb-3">
                                <label
                                    htmlFor="lab-test-search-term"
                                    className="col-3 col-form-label"
                                >
                                    Lab Test Search Terms:
                                </label>
                                <div className="col-9">
                                    <textarea
                                        type="text-area"
                                        className="form-control"
                                        style={{height:'120px'}}
                                        value={labTestSearchTerms}
                                        onChange={(e) => {
                                            setLabTestSearchTerms(e.target.value);
                                        }}
                                        onBlur={(e)=>{
                                            if(e.target.value === ''){
                                                setValidateLabTestSearchTerms(false)
                                            }else{
                                                setValidateLabTestSearchTerms(true)
                                            }
                                        }}
                                        id="lab-test-search-term"
                                        placeholder="Lab Test Search terms - needs to be semicolon ( ; ) separated values"
                                        autoComplete="off"
                                    />
                                    {validateLabTestSearhTerms === false ?(
                                        <div className="text-danger">
                                            Please give Lab Test Search Terms.
                                        </div>
                                    ):null}
                                                 
                                </div>
                            </div>

                            {/* Lab Test Summary Points */}
                            <div className="row mb-3">
                                <label
                                    htmlFor="lab-test-summary-points"
                                    className="col-3 col-form-label"
                                >
                                    Lab Test Summary Points:
                                </label>
                                <div className="col-9">
                                    <textarea
                                        type="text-area"
                                        className="form-control"
                                        style={{height:'120px'}}
                                        value={labTestSummaryPoints}
                                        onChange={(e) => {
                                            setLabTestSummaryPoints(e.target.value);
                                        }}
                                        onBlur={(e)=>{
                                            if(e.target.value === ''){
                                                setValidateLabTestSummaryPoints(false)
                                            }else{
                                                setValidateLabTestSummaryPoints(true)
                                            }
                                        }}
                                        id="lab-test-summary_points"
                                        placeholder="Lab Test Summary Points - needs to be semicolon (;) separated values"
                                        autoComplete="off"
                                    />
                                    {validateLabTestSummaryPoints === false ?(
                                        <div className="text-danger">
                                            Please give Lab Test Summary Points.
                                        </div>
                                    ):null}
                                                 
                                </div>
                            </div>

                            {/* Lab Test Whatsapp Template ID
                            <div className="row mb-3">
                                <label
                                    htmlFor="lab-test-WA-TemplateId"
                                    className="col-3 col-form-label"
                                >
                                    Lab Test WA Template ID :
                                </label>
                                <div className="col-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={labTestWATemplateId}
                                        onChange={(e) => {
                                            setLabTestWATemplateId(e.target.value);
                                        }}
                                        onBlur={(e)=>{
                                            if(e.target.value === ''){
                                                setValidateLabTestWATemplateId(false)
                                            }else{
                                                setValidateLabTestWATemplateId(true)
                                            }
                                        }}
                                        id="lab-test-WA-templateId"
                                        placeholder="Lab Test Whatsapp Template ID"
                                        autoComplete="off"
                                    />
                                    {validateLabTestWATemplateId === false ?(
                                        <div className="text-danger">
                                            Please give Lab Test WA Template Id.
                                        </div>
                                    ):null}
                                                 
                                </div>
                            </div> */}

                            {/* Lab Test Spread Sheet Name */}
                            <div className="row mb-3">
                                <label
                                    htmlFor="lab-test-spreadsheet-name"
                                    className="col-3 col-form-label"
                                >
                                    Lab Test Spreadsheet Name :
                                </label>
                                <div className="col-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={labTestSpreadsheetName}
                                        onChange={(e) => {
                                            setLabTestSpreadsheetName(e.target.value);
                                        }}
                                        onBlur={(e)=>{
                                            if(e.target.value === ''){
                                                setValidateSpreadsheetName(false)
                                            }else{
                                                setValidateSpreadsheetName(true)
                                            }
                                        }}
                                        id="lab-test-WA-templateId"
                                        placeholder="Lab Test Google Sheet Name"
                                        autoComplete="off"
                                    />
                                    {validateLabTestWATemplateId === false ?(
                                        <div className="text-danger">
                                            Please give Lab Test WA Template Id.
                                        </div>
                                    ):null}
                                                 
                                </div>
                            </div>

                            {/* Lab Test Type */}
                            <div className="row mb-3">
                                <label
                                    htmlFor="labTestType"
                                    className="col-3 col-form-label"
                                >
                                    LabTestType :
                                </label>
                                <div className="col-9">
                                    <select
                                            className="form-select"
                                            id="type"
                                            value={labTestType}
                                            onChange={e=>setLabTestType(e.target.value)}
                                            placeholder="Select Lab Test Type"
                                            onBlur={(e)=>{
                                                if(e.target.value === 'Select Event Type'){
                                                    setValidateLabTestType(false)
                                                }else{
                                                    setValidateLabTestType(true)
                                                }
                                            }}
                                        >
                                            <option value="" disabled
                                                selected={labTestType === '' ? true : false}
                                            >Select Lab Test Type</option>
                                            <option selected={labTestType === 'Panel'?true:false} >Panel</option>
                                            <option selected={labTestType === 'Add On'?true:false} >Add On</option>
                                        </select>
                                        {
                                            validateLabTestType === false ? (
                                            <div className="text-danger">
                                                Please select Lab Test Type
                                            </div>
                                            ) : null
                                        }
                                </div>
                            </div>

                            {/* Lab Test Category */}
                            <div className="row mb-3">
                                <label
                                    htmlFor="lab-test-category"
                                    className="col-3 col-form-label"
                                >
                                    Lab Test Category: 
                                </label>
                                <div className="col-9">
                                    <Multiselect
                                        options={[...labTestCategory]}
                                        selectedValues={selectedLabTestCategory}
                                        onSelect={(selection) => {
                                            setSelectedLabTestCategory(selection);
                                            setValidateLabTestCategory(true)

                                        }}
                                        onRemove={(selectedList) => {setSelectedLabTestCategory(selectedList)}}
                                        displayValue="name"
                                        closeIcon='cancel'
                                        style={{
                                        chips: { 
                                            background: '#F5735A'
                                        },
                                    }}
                                    />
                                    {validateLabTestCategory === false ?(
                                        <div className="text-danger">
                                            Please give Lab Test Category.
                                        </div>
                                    ):null}
                                </div>
                            </div>

                            {/* Lab Partner */}
                            <div className="row mb-3">
                                <label
                                    htmlFor="lab-partner"
                                    className="col-3 col-form-label"
                                >
                                    Lab Partners : 
                                </label>
                                <div className="col-9">
                                    <Multiselect
                                        options={[...labPartner]}
                                        selectedValues={selectedLabPartner}
                                        onSelect={(selection) => {
                                            setSelectedLabPartner([...selection])
                                            setValidateLabPartner(true)
                                        }}
                                        onRemove={(deletion) => {
                                            setSelectedLabPartner([...deletion])
                                            const nonDeletedId = new Set(deletion.map((item) => item.id))
                                            setSelectedAvailableTests(selectedAvailableTests.filter((item) => nonDeletedId.has(Object.keys(item)[0])));
                                             }}
                                        displayValue="name"
                                        closeIcon='cancel'
                                        style={{
                                        chips: { 
                                            background: '#F5735A'
                                        },
                                        }}
                                    />
                                    {validateLabPartner === false ?(
                                        <div className="text-danger">
                                            Please give Lab Partner.
                                        </div>
                                    ):null}
                                    
                                </div>
                            </div>

                            {/* Tests Available */}
                            {selectedLabPartner.length>0?
                            <div className="row mb-3">
                                <label
                                    htmlFor="lab-test-available"
                                    className="col-3 col-form-label"
                                >
                                    Tests Available:
                                </label>
                                <div className="col-9">
                                    {selectedLabPartner.map((item)=>{
                                        return(
                                            <TestAvailableFields
                                                key={item.id}
                                                labPartnerId={item.id}
                                                label={item.name}
                                                availableTests={availableTests}
                                                setAvailableTests={setAvailableTests}
                                                selectedAvailableTests={selectedAvailableTests}
                                                setSelectedAvailableTests={setSelectedAvailableTests}
                                            />
                                            )
                                    })}
                                </div>
                            </div>
                            :null}

                            {/* Part of Panel */}
                            <div className="row mb-3">
                                <label
                                    htmlFor="pa"
                                    className="col-3 col-form-label"
                                >
                                    Part of Panels: 
                                </label>
                                <div className="col-9">
                                    <Multiselect
                                        options={allSkus.filter(item=> (item.name.includes("Test") || item.name.includes("Panel") || item.name.includes("Package") || item.name.includes("Checkup")) )}
                                        selectedValues={partOfPanel}
                                        onSelect={(selectedSkus) => {
                                            setPartOfPanel(selectedSkus)
                                        }}
                                        onRemove={(selectedList) => {setPartOfPanel(selectedList)}}
                                        displayValue="name"
                                        closeIcon='cancel'
                                        placeholder='Select if any'
                                        style={{
                                        chips: { 
                                            background: '#F5735A'
                                        },
                                    }}
                                    />
                                    <span className="text-secondary">( Select if lab test is part of other panels )</span>
                                </div>
                            </div>

                            {/* Recommended SKUs Multi select */}
                            <div className="row mb-3">
                                <label
                                    htmlFor="recommended-lab-test"
                                    className="col-3 col-form-label"
                                >
                                    Recommended SKUs: 
                                </label>
                                <div className="col-9">
                                    <Multiselect
                                        options={allSkus}
                                        selectedValues={recommendedSkus}
                                        onSelect={(selectedSkus) => {
                                            setRecommendedSkus(selectedSkus)
                                        }}
                                        onRemove={(selectedList) => {setRecommendedSkus(selectedList)}}
                                        displayValue="name"
                                        closeIcon='cancel'
                                        style={{
                                        chips: { 
                                            background: '#F5735A'
                                        },
                                    }}
                                    />
                                </div>
                            </div>

                            {/* Applicable Badges  */}
                            <div className="row mb-3">
                                <label
                                    htmlFor="lab-test-badges"
                                    className="col-3 col-form-label"
                                >
                                    Lab Test Badges :
                                </label>
                                <div className="col-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={allBadges}
                                        onChange={(e) => {
                                            setAllBadges(e.target.value);
                                        }}
                                        onBlur={(e)=>{
                                            if(e.target.value === ''){
                                                setValidateAllBadges(false)
                                            }else{
                                                setValidateAllBadges(true)
                                            }
                                        }}
                                        id="lab-test_badges"
                                        placeholder="Lab Test Badges. Need to be semicolon (;) separated values"
                                        autoComplete="off"
                                    />
                                    {validateAllBadges === false ?(
                                        <div className="text-danger">
                                            Please give Lab Test Badges.
                                        </div>
                                    ):null}
                                                 
                                </div>
                            </div>

                            {/* Lab Test Faq */}
                            <div className="row mb-3">
                                <label
                                    htmlFor="lab-test-faq"
                                    className="col-3 col-form-label"
                                >
                                    Lab Test FAQs:
                                </label>
                                <div className="col-9">
                                    <RichTextEditor
                                        value={faq}
                                        setValue={setFaq}
                                    />
                                    {validateFaq === false ?(
                                        <div className="text-danger">
                                            Please provide Lab Test FAQs.
                                        </div>
                                    ):null}
                                                 
                                </div>
                            </div>

                            {/* Lab Test Thumbnail */}
                            <LabTestThumbanailUpload
                                thumbnailUrl={thumbnailUrl}
                                src={src}
                                setThumbnailUrl={setThumbnailUrl}
                            />


                        </div>
                        <div className="row">
                            <div className="justify-content-center d-flex" style={{marginTop:'1.75rem'}}>
                                <button 
                                    type="submit" 
                                    className="btn btn-success mx-2"
                                    onClick={()=>{handleSubmit()}}
                                >                   
                                     {labTestId?'Edit Lab Test':'Create New Lab Test'}
                                </button>
                                {
                                    labTestId ? (
                                        <button
                                            type="submit"
                                            className="btn btn-danger mx-2"
                                            onClick={()=>{handleDelete()}}
                                        >
                                            Delete Lab Test
                                        </button>
                                    ) : ""
                                }   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>   
</Navbar>
    
  )
}

export default CreateLabTest
