import axios from "axios";
import { ROOT_URL } from "../../../config";
import { forceLogout, LOGIN, setAuthentication } from "../../../actions/login";
import store from "../../../store";

export const ApiMethods = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

export const API_STATUS = {
  SUCCESS: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
};

// interceptor for tracking request
axios.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// interceptor for tracking response
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const { config, response } = error;

    return Promise.reject(error);
  }
);

export default axios;

// ApiClient function to make API calls, can be used in any file
export const ApiClient = async ({ method, endpoint, data = {}, feature }) => {
  try {
    // If GET request, add data to URL
    if (method === ApiMethods.GET && data) {
      let urlParams = "";
      Object.keys(data).forEach((key, index) => {
        urlParams += (index === 0 ? "?" : "&") + `${key}=${data[key]}`;
      });
      endpoint += urlParams;
    }

    const response = await axios.request({
      url: endpoint,
      method: method,
      withCredentials: true,
      credentials: "include",
      baseURL: ROOT_URL, // access the base URL from .env file
      ...(method !== ApiMethods.GET && { data: data }), // Add data to request if method is not GET
    });

    return response.data;
  } catch (err) {
    if (err.response) {
      if (
        err?.response?.status === API_STATUS.UNAUTHORIZED &&
        feature !== LOGIN
      ) {
        store.dispatch(forceLogout());
        return;
      } else if (feature === LOGIN) {
        store.dispatch(setAuthentication({ isAuthenticated: false }));
      }
    }

    return Promise.reject(err);
  }
};
