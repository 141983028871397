import React from "react";
import { DataGrid } from "@mui/x-data-grid";

const CommonTableContainer = ({
  rows,
  columns,
  pageSize,
  currentPage,
  totalRecords,
  onPageChange,
  height = "75vh",
  width = "100%",
  cardTitle,
  disableSelectionOnClick = true,
  additionalProps = {},
}) => {
  return (
    <div className="col-12">
      <div className="card">
        <div className="card-body">
        {cardTitle && <h4 className="card-title">{cardTitle}</h4>}
          <div className="tab-content">
            <div className="tab-pane show active" id="basic-datatable-preview">
              <div
                style={{
                  height,
                  width,
                }}
                className="mui-data-grid"
              >
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={pageSize}
                  page={currentPage}
                  paginationMode="server"
                  rowCount={totalRecords}
                  pagination
                  onPageChange={onPageChange}
                  disableSelectionOnClick={disableSelectionOnClick}
                  {...additionalProps}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonTableContainer;