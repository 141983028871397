import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import AsyncSelect from "react-select/async";
import Navbar from "../../navbar";
import { couponDetailsSelector } from "../../../selector/promoCodes/coupons";
import { ApiClient, ApiMethods } from "../../../middlewares/core/api/ApiClient";
import ApiEndpoints from "../../../middlewares/core/api/ApiEndpoints";
import { getSkusList } from "../../../actions/v1/skusAction";
import { getCouponDetails, updateCoupon } from "../../../actions/promoCodes";
import { GET_SKUS_LIST } from "../../../middlewares/types";

const CouponsForm = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const couponId = params?.couponId;

  useEffect(() => {
    dispatch(getCouponDetails(couponId.trim()));
  }, [couponId]);

  const couponDetails = useSelector((state) => couponDetailsSelector(state));

  const [offerName, setOfferName] = useState("");
  const [validateOfferName, setValidateOfferName] = useState(true);

  const [promoCode, setPromoCode] = useState("");
  const [validatePromoCode, setValidatePromoCode] = useState(true);

  const [type, setType] = useState("");
  const [validateType, setValidateType] = useState(true);

  const [discount, setDiscount] = useState("");
  const [validateDiscount, setValidateDiscount] = useState(true);

  const [maxUsage, setMaxUsage] = useState("");
  const [validateMaxUsage, setValidateMaxUsage] = useState(true);

  const [maxUsagePerUser, setMaxUsagePerUser] = useState("");
  const [validateMaxUsagePerUser, setValidateMaxUsagePerUser] = useState(true);

  const [startDate, setStartDate] = useState("");
  const [validateStartDate, setValidateStartDate] = useState(true);

  const [endDate, setEndDate] = useState("");
  const [validateEndDate, setValidateEndDate] = useState(true);

  const [maxAmount, setMaxAmount] = useState("");

  const [description, setDescription] = useState("");
  const [validateDescription, setValidateDescription] = useState(true);

  const [skusList, setSkusList] = useState([]);
  const [selectedSkusId, setSelectedSkusId] = useState([]);
  const [skuSearchField, setSkuSearchField] = useState("");
  const [selectAllSkus, setSelectAllSkus] = useState(false);
  const [skusPagination, setSkusPagination] = useState({
    pageNo: 1,
    pageSize: 20,
    status: 1,
  });

  useEffect(() => {
    if (couponDetails.couponDetails) {
      setOfferName(couponDetails.couponDetails.offerName);
      setPromoCode(couponDetails.couponDetails.couponId);
      setType(couponDetails.couponDetails.type);
      setDiscount(couponDetails.couponDetails.discount);
      setMaxUsage(couponDetails.couponDetails.maxUsage);
      setMaxUsagePerUser(couponDetails.couponDetails.maxUsagePerUser);
      setStartDate(
        moment(couponDetails.couponDetails.startDate).format("YYYY-MM-DDTHH:mm")
      );
      setEndDate(
        moment(couponDetails.couponDetails.endDate).format("YYYY-MM-DDTHH:mm")
      );
      setMaxAmount(couponDetails.couponDetails.maxAmount);
      setDescription(couponDetails.couponDetails.description);
      setSelectAllSkus(couponDetails.couponDetails.selectAllSkus === 1);

      // preload the selected skus
      if (couponDetails?.skus?.length > 0) {
        setSelectedSkusId(couponDetails.skus);
      }
    }
  }, [couponDetails]);

  // fetch skus if page number is changed
  useEffect(() => {
    dispatch(getSkusList(skusPagination));
  }, [skusPagination?.pageNo]);

  const { skus, isLoading } = useSelector((state) => state.skus);

  // appending new skus to existing skus
  useEffect(() => {
    if (skus && skus?.data.length > 0) {
      setSkusList((prevSkus) => [...prevSkus, ...skus.data]);
    }
  }, [skus]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "/assets/js/advancedForm.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const onSearchGetSkus = async (searchValue) => {
    try {
      const res = await ApiClient({
        method: ApiMethods.GET,
        endpoint: ApiEndpoints.skus,
        data: { pageNo: 1, pageSize: 30, status: 1, searchValue },
        feature: GET_SKUS_LIST,
      });

      if (res?.responseStatus?.statusCode) {
        return res?.responseData?.data;
      }
    } catch (error) {
      return [];
    }
  };

  const onMenuScrollToBottom = (e) => {
    const { totalRecords, pageNo, pageSize } = skus?.pagination || {};

    if (pageNo * pageSize >= totalRecords) return;

    setSkusPagination({
      ...skusPagination,
      pageNo: skusPagination.pageNo + 1,
    });
  };

  const onChange = (selectedSkus) => {
    if (selectedSkus?.length > 0) {
      setSelectedSkusId(selectedSkus);
    }
  };

  const handleSubmit = () => {
    dispatch(
      updateCoupon({
        offerName,
        promoCode,
        type,
        discount: parseFloat(discount),
        maxUsage: parseInt(maxUsage, 10),
        maxUsagePerUser: parseInt(maxUsagePerUser, 10),
        startDate: moment(startDate).utc().format("YYYY-MM-DD HH:mm:ss"),
        endDate: moment(endDate).utc().format("YYYY-MM-DD HH:mm:ss"),
        maxAmount: parseInt(maxAmount, 10),
        description,
        applicableSkus: selectAllSkus
          ? null
          : selectedSkusId?.map((item) => item.skuId) || [],
        selectAllSkus: selectAllSkus ? 1 : 0,
      })
    );
    history.push("/coupons");
  };

  return (
    <Navbar pageTitle={"Update Coupon"}>
      <div className="row ">
        <div className="col-10">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10">
                  {/* Offer Name field */}

                  <div className="row mb-3">
                    <label
                      htmlFor="offer-name"
                      className="col-2 col-form-label"
                    >
                      Offer Name:
                    </label>
                    <div className="col-9">
                      <input
                        type="text"
                        className="form-control"
                        value={offerName}
                        onChange={(e) => {
                          setOfferName(e.target.value);
                        }}
                        onBlur={(e) => {
                          if (e.target.value === "") {
                            setValidateOfferName(false);
                          } else {
                            setValidateOfferName(true);
                          }
                        }}
                        id="offer-name"
                        placeholder="Offer Name"
                        autoComplete="off"
                      />
                      {validateOfferName === false ? (
                        <div className="text-danger">
                          Please give a offer name.
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {/* Promo Code field */}

                  {/* Type Select */}
                  <div className="row mb-3">
                    <label htmlFor="type" className="col-2 col-form-label">
                      Type:
                    </label>
                    <div className="col-9">
                      <select
                        className="form-select"
                        id="type"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        placeholder="Select Voucher Type"
                        onBlur={(e) => {
                          if (e.target.value === "Select Event Type") {
                            setValidateType(false);
                          } else {
                            setValidateType(true);
                          }
                        }}
                      >
                        <option value="" disabled selected>
                          Select Event Type
                        </option>
                        <option>Discount</option>
                        <option>Flat</option>
                      </select>
                      {validateType === false ? (
                        <div className="text-danger">
                          Please select event type
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {/* Discount Amount / Percentage Field */}
                  <div className="row mb-3">
                    <label htmlFor="discount" className="col-2 col-form-label">
                      {type === "Flat" ? "Amount: " : "Percentage %: "}
                    </label>
                    <div className="col-9">
                      <input
                        type="text"
                        className="form-control"
                        value={discount}
                        onChange={(e) => {
                          setDiscount(e.target.value);
                        }}
                        onBlur={(e) => {
                          if (e.target.value === "") {
                            setValidateDiscount(false);
                          } else {
                            setValidateDiscount(true);
                          }
                        }}
                        id="discount"
                        placeholder="Discount"
                        autoComplete="off"
                      />
                      {validateDiscount === false ? (
                        <div className="text-danger">
                          Please set voucher prefix
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {/* Max Usage field */}
                  <div className="row mb-3">
                    <label htmlFor="prefix" className="col-2 col-form-label">
                      Max Usage:
                    </label>
                    <div className="col-9">
                      <input
                        type="text"
                        className="form-control"
                        value={maxUsage}
                        onChange={(e) => {
                          setMaxUsage(e.target.value);
                        }}
                        onBlur={(e) => {
                          if (e.target.value === "") {
                            setValidateMaxUsage(false);
                          } else {
                            setValidateMaxUsage(true);
                          }
                        }}
                        id="maxUsage"
                        placeholder="Number of times coupon can be used"
                        autoComplete="off"
                      />
                      {validateMaxUsage === false ? (
                        <div className="text-danger">Please set max usage</div>
                      ) : null}
                    </div>
                  </div>

                  {/* Max Usage Per User field */}
                  <div className="row mb-3">
                    <label htmlFor="prefix" className="col-2 col-form-label">
                      Max Usage Per User:
                    </label>
                    <div className="col-9">
                      <input
                        type="text"
                        className="form-control"
                        value={maxUsagePerUser}
                        onChange={(e) => {
                          setMaxUsagePerUser(e.target.value);
                        }}
                        onBlur={(e) => {
                          if (e.target.value === "") {
                            setValidateMaxUsagePerUser(false);
                          } else {
                            setValidateMaxUsagePerUser(true);
                          }
                        }}
                        id="maxUsagePerUser"
                        placeholder="Number of times coupon can be used per user"
                        autoComplete="off"
                      />
                      {validateMaxUsagePerUser === false ? (
                        <div className="text-danger">
                          Please set max usage per user
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {/* Start Date Field */}
                  <div className="row mb-3">
                    <label htmlFor="prefix" className="col-2 col-form-label">
                      Start Date:
                    </label>
                    <div className="col-9">
                      <input
                        type="datetime-local"
                        className="form-control"
                        value={startDate}
                        onChange={(e) => {
                          setStartDate(e.target.value);
                        }}
                        onBlur={(e) => {
                          if (e.target.value === "") {
                            setValidateStartDate(false);
                          } else {
                            setValidateStartDate(true);
                          }
                        }}
                        id="startDate"
                        placeholder="Start Date"
                        autoComplete="off"
                      />
                      {validateStartDate === false ? (
                        <div className="text-danger">
                          Please set coupon's Start Date
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {/* End Date Field  */}
                  <div className="row mb-3">
                    <label htmlFor="prefix" className="col-2 col-form-label">
                      End Date:
                    </label>
                    <div className="col-9">
                      <input
                        type="datetime-local"
                        className="form-control"
                        value={endDate}
                        onChange={(e) => {
                          setEndDate(e.target.value);
                        }}
                        onBlur={(e) => {
                          if (e.target.value === "") {
                            setValidateEndDate(false);
                          } else {
                            setValidateEndDate(true);
                          }
                        }}
                        id="endDate"
                        placeholder="End Date"
                        autoComplete="off"
                      />
                      {validateEndDate === false ? (
                        <div className="text-danger">
                          Please set coupon's End Date
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {/* Max Amount Field */}
                  <div className="row mb-3">
                    <label htmlFor="prefix" className="col-2 col-form-label">
                      Max Amount:
                    </label>
                    <div className="col-9">
                      <input
                        type="text"
                        className="form-control"
                        value={maxAmount}
                        onChange={(e) => {
                          setMaxAmount(e.target.value, 10);
                        }}
                        id="maxAmount"
                        placeholder="Set Max amount for Voucher"
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  {/* Description Field */}
                  <div className="row mb-3">
                    <label htmlFor="prefix" className="col-2 col-form-label">
                      Description:
                    </label>
                    <div className="col-9">
                      <input
                        type="text"
                        className="form-control"
                        value={description}
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                        onBlur={(e) => {
                          if (e.target.value === "") {
                            setValidateDescription(false);
                          } else {
                            setValidateDescription(true);
                          }
                        }}
                        id="description"
                        placeholder="Description for voucher"
                        autoComplete="off"
                      />
                      {validateDescription === false ? (
                        <div className="text-danger">
                          Please set coupon description
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {/* Applicable Plans Multi select */}
                  <div className="row mb-1">
                    <label htmlFor="prefix" className="col-2 col-form-label">
                      Applicable SKUs:
                    </label>
                    <div className="col-9">
                      <AsyncSelect
                        value={selectedSkusId}
                        isMulti={true}
                        isDisabled={selectAllSkus}
                        defaultOptions={skusList}
                        onChange={onChange}
                        isLoading={isLoading}
                        loadOptions={onSearchGetSkus}
                        inputValue={skuSearchField}
                        onInputChange={setSkuSearchField}
                        placeholder={"Select SKUs"}
                        noOptionsMessage={() => "Not SKU Found"}
                        getOptionLabel={(option) =>
                          `${option.skuName} - ${option.skuId}`
                        }
                        getOptionValue={(option) => option.skuId}
                        onMenuScrollToBottom={onMenuScrollToBottom}
                      />

                      <div className="my-1">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          checked={selectAllSkus}
                          onChange={(e) => {
                            setSelectAllSkus(!selectAllSkus);
                          }}
                        />
                        <label class="form-check-label px-2">Select All</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="justify-content-center d-flex"
                    style={{ marginTop: "1.75rem" }}
                  >
                    <button
                      type="submit"
                      className="btn btn-success"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Update Coupons
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Navbar>
  );
};

export default CouponsForm;
