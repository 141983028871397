import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getVoucherDetails, updateVoucher } from "../../../actions/promoCodes";
import { planSelector } from "../../../selector/plan";
import { useHistory } from "react-router";
import { getPlans } from "../../../actions/plan";
import Multiselect from "multiselect-react-dropdown";
import { getAllSKUs } from "../../../actions/SKU";
import { SKUSelector } from "../../../selector/SKU";
import Navbar from "../../navbar";
import { voucherDetailsSelector } from "../../../selector/promoCodes/vouchers";
import moment from "moment";

const VouchersForm = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const voucherId = props.match.params.voucherId;

  useEffect(() => {
    dispatch(getVoucherDetails(voucherId));
  }, [dispatch, voucherId]);

  const voucherDetails = useSelector((state) => voucherDetailsSelector(state));
  console.log(voucherDetails);

  const [offerName, setOfferName] = useState("");
  const [validateOfferName, setValidateOfferName] = useState(true);

  const [type, setType] = useState("");
  const [validateType, setValidateType] = useState(true);

  const [discount, setDiscount] = useState("");
  const [validateDiscount, setValidateDiscount] = useState(true);

  const [lifeSpan, setLifeSpan] = useState("");
  const [validateLifeSpan, setValidateLifeSpan] = useState(true);

  const [startDate, setStartDate] = useState("");
  const [validateStartDate, setValidateStartDate] = useState(true);

  const [endDate, setEndDate] = useState("");
  const [validateEndDate, setValidateEndDate] = useState(true);

  const [maxAmount, setMaxAmount] = useState("");

  const [description, setDescription] = useState("");
  const [validateDescription, setValidateDescription] = useState(true);

  const [generateMultiple, setGenerateMultiple] = useState("");
  const [validateGenerateMultiple, setValidateGenerateMultiple] =
    useState(true);

  const [allSkus, setAllSkus] = useState([]);

  const [applicableSkus, setApplicableSkus] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [validateApplicableSkus, setValidateApplicableSkus] = useState(true);

  useEffect(() => {
    if (voucherDetails.voucherDetails) {
      setOfferName(voucherDetails.voucherDetails.offerName);
      setType(voucherDetails.voucherDetails.type);
      setDiscount(voucherDetails.voucherDetails.discount);
      setLifeSpan(voucherDetails.voucherDetails.status);
      setStartDate(
        moment(voucherDetails.voucherDetails.startDate).format(
          "YYYY-MM-DDTHH:mm"
        )
      );
      setEndDate(
        moment(voucherDetails.voucherDetails.endDate).format("YYYY-MM-DDTHH:mm")
      );
      setMaxAmount(voucherDetails.voucherDetails.maxAmount);
      setDescription(voucherDetails.voucherDetails.description);
      setGenerateMultiple(voucherDetails.voucherDetails.generateMultiple);
      setApplicableSkus(voucherDetails.voucherDetails.applicableSkus);
    }
  }, [voucherDetails.voucherDetails]);

  useEffect(() => {
    dispatch(getAllSKUs({}));
  }, [dispatch]);
  const {skuData :skus} = useSelector((state) => SKUSelector(state));

  useEffect(() => {
    const skuOptionsArray = skus
      ? skus.map((item) => {
          return { name: `${item.skuName} - ${item.skuId}`, id: item.skuId };
        })
      : [];
    setAllSkus(skuOptionsArray);

    if (voucherDetails.skus) {
      const skuMap = new Map();

      voucherDetails.skus.forEach((sku) => {
        skuMap.set(sku.skuId, sku);
      });

      setApplicableSkus(skuOptionsArray.filter((item) => skuMap.has(item.id)));
    }
  }, [skus]);

  const handleSubmit = () => {
    dispatch(
      updateVoucher({
        offerName,
        voucherId,
        type,
        discount: parseFloat(discount),
        lifeSpan: parseInt(lifeSpan, 10),
        startDate: moment(startDate).utc().format("YYYY-MM-DD HH:mm:ss"),
        endDate: moment(endDate).utc().format("YYYY-MM-DD HH:mm:ss"),
        maxAmount: parseInt(maxAmount, 10),
        description,
        applicableSkus,
        generateMultiple: parseInt(generateMultiple, 10),
      })
    );
    history.push("/vouchers");
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "/assets/js/advancedForm.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);
  return (
    <Navbar pageTitle={"Update Voucher"}>
      <div className="row">
        <div className="col-10">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10">
                  {/* Offer Name field */}

                  <div className="row mb-3">
                    <label
                      htmlFor="offer-name"
                      className="col-2 col-form-label"
                    >
                      Offer Name:
                    </label>
                    <div className="col-9">
                      <input
                        type="text"
                        className="form-control"
                        value={offerName}
                        onChange={(e) => {
                          setOfferName(e.target.value);
                        }}
                        onBlur={(e) => {
                          if (e.target.value === "") {
                            setValidateOfferName(false);
                          } else {
                            setValidateOfferName(true);
                          }
                        }}
                        id="offer-name"
                        placeholder="Offer Name"
                        autoComplete="off"
                      />
                      {validateOfferName === false ? (
                        <div className="text-danger">
                          Please give a offer name.
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {/* Type Select */}
                  <div className="row mb-3">
                    <label htmlFor="type" className="col-2 col-form-label">
                      Type:
                    </label>
                    <div className="col-9">
                      <select
                        className="form-select"
                        id="type"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        placeholder="Select Voucher Type"
                        onBlur={(e) => {
                          if (e.target.value === "Select Event Type") {
                            setValidateType(false);
                          } else {
                            setValidateType(true);
                          }
                        }}
                      >
                        <option value="" disabled selected>
                          Select Event Type
                        </option>
                        <option>Discount</option>
                        <option>Flat</option>
                      </select>
                      {validateType === false ? (
                        <div className="text-danger">
                          Please select event type
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {/* Discount Amount / Percentage Field */}
                  <div className="row mb-3">
                    <label htmlFor="discount" className="col-2 col-form-label">
                      {type === "Flat" ? "Amount: " : "Percentage %: "}
                    </label>
                    <div className="col-9">
                      <input
                        type="text"
                        className="form-control"
                        value={discount}
                        onChange={(e) => {
                          setDiscount(e.target.value);
                        }}
                        onBlur={(e) => {
                          if (e.target.value === "") {
                            setValidateDiscount(false);
                          } else {
                            setValidateDiscount(true);
                          }
                        }}
                        id="discount"
                        placeholder="Discount"
                        autoComplete="off"
                      />
                      {validateDiscount === false ? (
                        <div className="text-danger">
                          Please set voucher prefix
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {/* Life Span field */}
                  <div className="row mb-3">
                    <label htmlFor="prefix" className="col-2 col-form-label">
                      Life Span:
                    </label>
                    <div className="col-9">
                      <input
                        type="text"
                        className="form-control"
                        value={lifeSpan}
                        onChange={(e) => {
                          setLifeSpan(e.target.value);
                        }}
                        onBlur={(e) => {
                          if (e.target.value === "") {
                            setValidateLifeSpan(false);
                          } else {
                            setValidateLifeSpan(true);
                          }
                        }}
                        id="lifeSpan"
                        placeholder="Number of times voucher can be used"
                        autoComplete="off"
                      />
                      {validateLifeSpan === false ? (
                        <div className="text-danger">
                          Please set voucher life span
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {/* Start Date Field */}
                  <div className="row mb-3">
                    <label htmlFor="prefix" className="col-2 col-form-label">
                      Start Date:
                    </label>
                    <div className="col-9">
                      <input
                        type="datetime-local"
                        className="form-control"
                        value={startDate}
                        onChange={(e) => {
                          setStartDate(e.target.value);
                        }}
                        onBlur={(e) => {
                          if (e.target.value === "") {
                            setValidateStartDate(false);
                          } else {
                            setValidateStartDate(true);
                          }
                        }}
                        id="startDate"
                        placeholder="Start Date"
                        autoComplete="off"
                      />
                      {validateStartDate === false ? (
                        <div className="text-danger">
                          Please set voucher's Start Date
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {/* End Date Field  */}
                  <div className="row mb-3">
                    <label htmlFor="prefix" className="col-2 col-form-label">
                      End Date:
                    </label>
                    <div className="col-9">
                      <input
                        type="datetime-local"
                        className="form-control"
                        value={endDate}
                        onChange={(e) => {
                          setEndDate(e.target.value);
                        }}
                        onBlur={(e) => {
                          if (e.target.value === "") {
                            setValidateEndDate(false);
                          } else {
                            setValidateEndDate(true);
                          }
                        }}
                        id="endDate"
                        placeholder="End Date"
                        autoComplete="off"
                      />
                      {validateEndDate === false ? (
                        <div className="text-danger">
                          Please set voucher's End Date
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {/* Max Amount Field */}
                  <div className="row mb-3">
                    <label htmlFor="prefix" className="col-2 col-form-label">
                      Max Amount:
                    </label>
                    <div className="col-9">
                      <input
                        type="text"
                        className="form-control"
                        value={maxAmount}
                        onChange={(e) => {
                          setMaxAmount(e.target.value);
                        }}
                        id="maxAmount"
                        placeholder="Set Max amount for Voucher"
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  {/* Description Field */}
                  <div className="row mb-3">
                    <label htmlFor="prefix" className="col-2 col-form-label">
                      Description:
                    </label>
                    <div className="col-9">
                      <input
                        type="text"
                        className="form-control"
                        value={description}
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                        onBlur={(e) => {
                          if (e.target.value === "") {
                            setValidateDescription(false);
                          } else {
                            setValidateDescription(true);
                          }
                        }}
                        id="description"
                        placeholder="Description for voucher"
                        autoComplete="off"
                      />
                      {validateDescription === false ? (
                        <div className="text-danger">
                          Please set voucher description
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {/* Applicable Plans Multi select */}
                  <div className="row mb-3">
                    <label htmlFor="prefix" className="col-2 col-form-label">
                      Applicable SKUs:
                    </label>
                    <div className="col-9">
                      <Multiselect
                        options={allSkus}
                        selectedValues={applicableSkus}
                        onSelect={(selectedSkus) => {
                          setApplicableSkus(selectedSkus);
                          setValidateApplicableSkus(true);
                        }}
                        onRemove={(selectedList) => {
                          setApplicableSkus(selectedList);
                        }}
                        displayValue="name"
                        closeIcon="cancel"
                        style={{
                          chips: {
                            background: "#F5735A",
                          },
                        }}
                      />
                    </div>
                  </div>

                  {/* Generate Multiple Field  */}
                  <div className="row mb-1">
                    <label htmlFor="prefix" className="col-2 col-form-label">
                      Generate Multiple:
                    </label>
                    <div className="col-9">
                      <input
                        type="text"
                        className="form-control"
                        value={generateMultiple}
                        onChange={(e) => {
                          setGenerateMultiple(e.target.value);
                        }}
                        onBlur={(e) => {
                          if (e.target.value === "") {
                            setValidateGenerateMultiple(false);
                          } else {
                            setValidateGenerateMultiple(true);
                          }
                        }}
                        id="generateMultiple"
                        placeholder="Specify amount of vouchers requried"
                        autoComplete="off"
                      />
                      {validateGenerateMultiple === false ? (
                        <div className="text-danger">
                          Please set voucher prefix
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="justify-content-center d-flex"
                    style={{ marginTop: "1.75rem" }}
                  >
                    <button
                      type="submit"
                      className="btn btn-success"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Update Vouchers
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Navbar>
  );
};

export default VouchersForm;
