import {
  GET_EXPERT_AVAILABILITY,
  RESET_EXPERT_AVAILABILITY,
  SET_EXPERT_AVAILABILITY,
  SET_EXPERT_AVAILABILITY_ERROR,
} from "../../middlewares/types";

export const getExpertAvailability = (data) => ({
  type: GET_EXPERT_AVAILABILITY,
  payload: data,
});

export const setExpertAvailability = (data) => ({
  type: SET_EXPERT_AVAILABILITY,
  payload: data,
});

export const setExpertAvailabilityError = (data) => ({
  type: SET_EXPERT_AVAILABILITY_ERROR,
  payload: data,
});

export const resetExpertAvailability = () => ({
  type: RESET_EXPERT_AVAILABILITY,
});
